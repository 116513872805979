import React, { useState, useEffect, useRef } from 'react';

const MaxHeightInput = ({ placeholder, value, onChange, argBaseHeight, argMaxHeight, readOnly, fontSize, handleMouseDown, onClick, externalTextareaRef, autoFocus }) => {
    const [text, setText] = useState(value);
    const maxHeight = argMaxHeight || 140;
    const internalTextareaRef = useRef(null);
    const textareaRef = externalTextareaRef || internalTextareaRef;

    // Function to handle text change
    const handleChange = (e) => {
        setText(e.target.value);
        onChange(e.target.value);
    };

    // Adjust textarea height based on content
    useEffect(() => {
        if (textareaRef.current) {
            const baseHeight = argBaseHeight || 44; 

            textareaRef.current.style.height = `${baseHeight}px`;

            let desiredHeight = textareaRef.current.scrollHeight;
            if (text === '') {
                desiredHeight = baseHeight;
            } else {
                desiredHeight = Math.max(desiredHeight, baseHeight);
            }

            textareaRef.current.style.height = `${Math.min(desiredHeight, maxHeight)}px`;
        }
    }, [text]);


    useEffect(() => {
        setText(value);
    }, [value]);

    return (
        <textarea
        autoFocus = {autoFocus}
            className="form-control round-input border-input chatBox "
            placeholder={placeholder}
            value={text}
            onChange={handleChange}
            onMouseDown={handleMouseDown}
            onClick={onClick}
            readOnly={readOnly}
            ref={textareaRef}
            style={{
                overflow: (textareaRef.current && textareaRef.current.style.height !== `${maxHeight}px`) ? 'hidden' : 'auto',
                resize: 'none',
                fontSize: fontSize || '14px',
            }}
        />

    );
};

export default MaxHeightInput;
