import React, { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { useAuth } from "../../Auth/AuthContext";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useModal } from "../../components/common/Modal/ModalContext";
import dropbox from "../../assets/img/icon/dropbox.svg";
import googleDrive from "../../assets/img/icon/google-drive.svg";
import oneDrive from "../../assets/img/icon/onedrive.svg";
import youtube from "../../assets/img/icon/youtube.svg";
import uploadBox from "../../assets/img/upload/upload-box.png";
import { fetchDataPost } from "../../hooks/apiUtils";
import { showToast } from "../../components/common/toast";
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg";
import JSZip from "jszip";
import LoadingModal from "../../components/common/Modal/LoadingModal";
import textFileIcon from "../../assets/img/icon/text-file.svg";
import { when } from "jquery";
import { useTranslation } from "../../components/common/LanguageProvider";

var upload_arr = [];
var upload_status = [];
function ImportFile({ whenNewFileUploaded, minutes }) {
  const { hashedId, userData } = useAuth();
  const fileInput = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(null);
  const [service, setService] = useState(null);
  const [urlUpload, setUrlUpload] = useState(false);
  const [stillProcessing, setStillProcessing] = useState(false);
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [progressFileName, setProgressFileName] = useState("");
  const [typedText2, setTypedText2] = useState("");
  const [webURL, setWebURL] = useState("");
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const uploadedOrderId = useRef(null);
  const [urlWarning, setUrlWarning] = useState(null);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState(null);
  var urlIndex = 0;
  const [attachListener, setAttachListener] = useState(false);

  var attempt = 0;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (attachListener) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [attachListener]);
  const handleFileDrop = (file) => {
    const event = {
      target: {
        files: [file],
      },
    };
    handleFileChange(event);
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {
    if (webURL.length > 0) {
      if (isValidHttpUrl(webURL)) {
        setUrlWarning("");
      } else {
        setUrlWarning(
          "Url is not valid please use 'https://website.com' format"
        );
      }
    } else {
      setUrlWarning(null);
    }
  }, [webURL]);

  const handleFileChange = (e) => {
    // const newFiles = [...files, ...e.target.files];
    const newFiles = [...e.target.files];
    // Filtering for valid and invalid files
    console.log(e.target.files);
    const newValidFiles = newFiles.filter(
      (file) =>
        file.type === "text/plain" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword" ||
        // file.type === 'application/msword' ||
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png"
    );

    const invalidFiles = newFiles.filter(
      (file) =>
        file.type !== "text/plain" &&
        file.type !== "application/msword" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        // file.type !== 'application/msword' &&
        file.type !== "application/pdf" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
    );

    if (invalidFiles.length > 0) {
      showToast(t("File is not a valid audio or video file."), {
        type: "error",
        position: "top-center",
      });
    }
    // Handle the upload logic
    if (newValidFiles.length > 0) {
      newValidFiles.forEach((file) => {
        onUploadFile(file);
      });
    }
    setFiles(newFiles); // Assuming you have a state called 'files' to store the list of files
  };

  const removeSpecialCharsAndTrim = (filename) => {
    // Remove special characters (#, &, *) and trim whitespace
    let temp = filename.replace(/[&#*]/g, "").trim();
    return temp;
  };

  function outOfCredit() {
    return minutes <= 0;
  }

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
  }

  const onUploadFinish = async (oid, sk) => {
    const orderid = sk.replace("#Eskritor#", "");
    const pk = "User%23" + hashedId;
    const tsk = "%23Eskritor%23" + orderid;
    const res = await fetch(
      `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/filedirectory/geteskritorcontent?pk=${pk}&sk=${tsk}&application=eskritor&platform=web`
    );

    if (res.ok) {
      const data = await res.json();
      if (data.status == "Completed") {
        window.location.href = `https://editor.eskritor.com/${orderid}/${hashedId}?uid=${hashedId}&lang=${localStorage.getItem(
          "dashboard-language"
        )}`;
      } else {
        if (attempt < 5) {
          attempt++;
          setTimeout(() => onUploadFinish(oid, sk), 3000);
        } else {
          attempt = 0;
          navigate("/files");
        }
      }
    } else {
      if (res.status === 503) {
        if (attempt < 5) {
          attempt++;
          setTimeout(() => onUploadFinish(oid, sk), 3000);
        } else {
          attempt = 0;
          navigate("/files");
        }
      } else {
        console.error("Error uploading file:", res);
        showToast(t("Error uploading file"), { type: "error" });
        setLoading(false);
      }
    }
  };

  const onUploadFile = async (file) => {
    setLoading(true);

    showToast(
      t("It might take a few minutes to extract content") + ": " + file.name,
      { type: "success" }
    );

    console.log(userData, "userdata");

    handleUploadEskritor(file.name, file);
  };
  function getTitle(raw_text) {
    // Split the input text into an array of words.
    const words = raw_text.split(/\s+/);

    // Select the first three words, then join them back into a string.
    const title = words.slice(0, 3).join(" ");

    return title;
  }

  function lang_picker(raw_text, fileName) {
    setLoading(true);
    // trim \n
    raw_text = raw_text.trim("\n");
    if (raw_text.length > 0) {
      setTypedText2("");
      // document.getElementById('loading-type').style.display = "block";
      var currentDateTime = new Date();
      var resultInSeconds = currentDateTime.getTime() / 1000;
      var link = document.createElement("a");
      link.download = fileName ? fileName : getTitle(raw_text);
      var blob = new Blob([raw_text], { type: "text/plain" });
      link.href = window.URL.createObjectURL(blob);
      handleUploadEskritor(link.download, blob);
    } else {
      alert("Please don't leave it blank");
    }
  }
  async function handleUploadEskritor(filename, file) {
    setLoading(true);
    console.log(filename, file, hashedId);

    const generalUploadUrl = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&filename=${filename}`;

    try {
      const response = await fetch(generalUploadUrl);
      const data = await response.json();

      const presignedUrl = data["the_url"];
      uploadedOrderId.current = data["orderid"];
      console.log("Received a presigned URL:", presignedUrl);
      if (!response.ok) {
        if (response.status === 406) {
          if (data.limitSurpassed) {
            showModal("UPGRADE-RECORD");
          } else {
            showToast(data.message, { type: "error" });
          }
        } else {
          showToast(data.message, { type: "error" });
        }
        return;
      }

      await uploadS3(presignedUrl, file, function (percent) {
        console.log(percent);
      });
      const url =
        process.env.REACT_APP_MODE === "development"
          ? `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/textextractor/initiator-dev`
          : process.env.REACT_APP_MODE === "beta"
          ? `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/textextractor/initiator-dev`
          : `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/textextractor/initiator-dev`;
      const res = await fetchDataPost(url, {
        hashed_id: hashedId,
        file_name: filename,
        app: "eskritor",
        platform: "web",
      });
      const responseData = await res.json();

      onUploadFinish(responseData.order_id, responseData.SK);
    } catch (err) {
      console.error("Error uploading file:", err);
      showToast(t("Error uploading file"), { type: "error" });
      setLoading(false);
    }
  }
  const uploadS3 = (url, file) => {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr);
          } else {
            reject(xhr);
          }
        }
      };
      xhr.open("PUT", url);
      xhr.send(file);
    });
  };

  return (
    <>
      <LoadingModal show={loading} message={`${t("Uploading")}`} />

      <div class="column">
       
        <div class=" card-margin ">
          <div class="card card-padding card-100  text-center justify-content-between ">
            <div class="form-group">
              <p class="ft-18-title mb-2">{t("Type/Paste")}</p>
              <textarea
                style={{ overflowY: "auto" }}
                value={typedText2}
                onChange={(e) => setTypedText2(e.target.value)}
                rows="3"
                class="form-control round-input type-file "
                placeholder={t(
                  "Type or paste your content here and start editing..."
                )}
              ></textarea>
            </div>
            <div class="text-center ">
              <button
                class="btn theme-btn min-btn-width mt-1"
                onClick={(e) => {
                  if (outOfCredit()) {
                    showModal("UPGRADE-GENERATE", {type: "type_content"});
                    return;
                  }
                  lang_picker(typedText2);
                }}
                disabled={typedText2.length === 0}
              >
                {t("Edit")}{" "}
              </button>
            </div>
          </div>
        </div>
        <div
          class=" card-margin"
          onClick={() => {
            if (outOfCredit()) {
              showModal("UPGRADE-GENERATE",  {type: "upload_file"});
              return;
            }
          }}
        >
          <div class="card card-padding card-100 flex-center  justify-content-between">
            <div class="text-center">
            <p class="ft-18-title mb-2">{t("Upload File")}</p>
            </div>
            <div
              className="drag-upload-div"
              style={{
                minHeight: "70px",
              }}
              onClick={() => fileInputRef.current.click()}
              onDragOver={(e) => {
                if (outOfCredit()) {
                  showModal("UPGRADE-GENERATE", {type: "upload_file"});
                  return;
                }
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.dataTransfer.items) {
                  for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === "file") {
                      const file = e.dataTransfer.items[i].getAsFile();
                      handleFileDrop(file);
                    }
                  }
                } else {
                  for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    handleFileDrop(e.dataTransfer.files[i]);
                  }
                }
              }}
            >
              <input
                type="file"
                disabled={outOfCredit()}
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".txt, .jpg, .jpeg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
              />

              <span class="d-inline-block theme-color mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </span>
              <p class="theme-color mb-2">{t("Drop File")}</p>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportFile;

// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
  try {
    const result = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};
