import React, { useEffect } from 'react';

function ConnectionStatus({ onSlowConnection, shouldCheck }) {
  useEffect(() => {
    const handleConnectionChange = () => {
      const { effectiveType } = navigator.connection;
      if ('slow-2g' !== effectiveType) {
        onSlowConnection();
      }
    };
    
    if (shouldCheck) {
      // Initial check
      handleConnectionChange();
      navigator.connection.addEventListener('change', handleConnectionChange);
      
      // Cleanup event listener on component unmount
      return () => {
        navigator.connection.removeEventListener('change', handleConnectionChange);
      };
    }
  }, [shouldCheck, onSlowConnection]);

  return null; // not rendering anything
}

export default ConnectionStatus;
