import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [modalType, setModalType] = useState(null);
    const [modalData, setModalData] = useState(null);

    function showModal(type, data, callbacks = {}) {
        setModalData({ ...data, ...callbacks });
        setModalType(type);
    }

    const hideModal = () => {
        setModalType(null);
        setModalData(null);
    };

    return (
        <ModalContext.Provider value={{ modalType, modalData, showModal, hideModal }}>
            {children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
