import React, { useRef, useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/font-awesome.css";
import "../../assets/css/custome.css";
import "../../assets/css/responsive.css";

import { useAuth } from "../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";

import rename from "../../assets/img/icon/rename.svg";
import fileMark from "../../assets/img/files/file-mark.svg";
import share from "../../assets/img/files/share.svg";
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";
import { showToast } from "../../components/common/toast";
import { useModal } from "../../components/common/Modal/ModalContext";
import { mdiAutoMode, mdiCreationOutline } from "@mdi/js";

import { Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "../../components/common/LanguageProvider";
import PromptGenerator from "../Dashboard/PromptGenerator";
import SettingsModal from "./SettingsModal";
import RichTextEditor from "./RichTextEditor";
import { Quill } from "react-quill";
import SideDiv from "./SideDiv";
import Icon from "@mdi/react";
function DocumentEditor({ handleOpenSidebar }) {
  const { sk, uid } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const params = new URLSearchParams(location.search);
  var oid = params.get("oid");
  const title = location.state?.title;
  const summary = location.state?.summary;
  const tone = location.state?.tone;
  const contentLanguage = useRef(location.state?.language);
  const format = location.state?.format;
  const length = location.state?.length;
  const { userData, hashedId, getStatus } = useAuth();
  const [userStatus, setUserStatus] = useState(null);

  const [fileHtmlContent, setFileHtmlContent] = useState("");

  const lastSavedVersion = useRef("");
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(
    localStorage.getItem("autoSaveEnabled") === "false" ? false : true
  );

  const [date, setDate] = useState(null);
  const [fileName, setFileName] = useState(null);
  const navigate = useNavigate();

  const [showInitialModal, setshowInitialModal] = useState(false);

  const [initialPrompt, setInitialPrompt] = useState("");
  const saveRef = useRef(null);
  const skRef = useRef(sk);
  const streamInProgress = useRef(false);

  const [showPromptsBox, setShowPromptsBox] = useState(false);

  const [editPromptFolders, setEditPromptFolders] = useState([]);

  const [promptsInProgress, setPromptsInProgress] = useState(false);
  const fileViewRef = useRef(null);

  const [contentLoading, setContentLoading] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);

  const isCreateGeneration = useRef(false);
  const [waitingStreamResponse, setWaitingStreamResponse] = useState(false);

  const { showModal, hideModal } = useModal();

  const autoScroll = useRef(true);
  const initlaPromptInputRef = useRef(null);
  const orderId = useRef(null);

  const [finishedStream, setFinishedStream] = useState(false);
  const [minutes, setMinutes] = useState(null);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const lastPrompts = useRef([]);

  const [buttonsPosition, setButtonsPosition] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  const [wordCounts, setWordCounts] = useState({});
  const [letterCounts, setLetterCounts] = useState({});

  const autoSaveTimer = useRef(null);

  const webSocket = useRef(null);
  const stopStreamRef = useRef(false);
  const firstContent = useRef(false);

  const quillRef = useRef(null);

  const [generationCredit, setGenerationCredit] = useState(0);
  const [selectedText, setSelectedText] = useState("");
  const [selectedPreText, setSelectedPreText] = useState("");
  const [selectedPostText, setSelectedPostText] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [showImproveButton, setShowImproveButton] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [generatedTexts, setGeneratedTexts] = useState([]);
  const [generatedTextToBeImproved, setGeneratedTextToBeImproved] =
    useState("");

  const generatedTextRef = useRef(null);

  const preceding_html = useRef(null);
  const following_html = useRef(null);

  useEffect(() => {
    if (userData) {
      setMinutes(userData.Minutes);
    }
  }, [userData]);

  useEffect(() => {
    if (!fileHtmlContent) {
      return;
    }
    const same = lastSavedVersion.current === fileHtmlContent;
    localStorage.setItem("unsaved", !same);
    if (autoSaveEnabled && !same && !firstContent.current) {
      clearTimeout(autoSaveTimer.current);
      autoSaveTimer.current = setTimeout(() => {
        onSave();
      }, 2000);
    }
  }, [fileHtmlContent]);

  useEffect(() => {
    if (showCompareModal) {
      setGeneratedTextToBeImproved(selectedText);
    }
  }, [showCompareModal]);

  const onSelectionChange = (range, source) => {
    console.log(range);
    if (range) {
      if (range.length > 0) {
        setShowImproveButton(true);
        setSelectedRange(range);
        setSelectedText(
          quillRef.current?.getEditor().getText(range.index, range.length)
        );
        setEditLinesData(range);
      } else {
        setShowImproveButton(false);
        hideButtons();
        setSelectedRange(null);
        setSelectedText("");
        setSelectedPreText("");
        setSelectedPostText("");
      }
    } else {
      setShowImproveButton(false);
      hideButtons();
      if (!showCompareModal) {
        setSelectedRange(null);
        setSelectedText("");
        setSelectedPreText("");
        setSelectedPostText("");
      }
    }
  };

  const selectAllDocument = () => {
    const range = {
      index: 0,
      length: quillRef.current.getEditor().getLength(),
    };
    setSelectedRange(range);
    setSelectedText(fileHtmlContent);
    setSelectedPreText("");
    setSelectedPostText("");
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (localStorage.getItem("unsaved") === "true") {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      var kc = event.which || event.keyCode;
      if (event.ctrlKey && String.fromCharCode(kc).toUpperCase() == "S") {
        event.preventDefault();
        console.log("ctrl+s");
        saveRef.current.click();
      }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onSave = async (show) => {
    if (!skRef.current) {
      return;
    } else {
      saveFile(show);
    }
  };

  const duplicateFile = async () => {
    const regex = /(\d+)\)(\.[^.]+)?$/; // This regex matches the duplicate indicator and the file extension if present.
    const match = fileName.match(regex);

    let newFileName;
    if (match) {
      const num = parseInt(match[1], 10) + 1; // Increment the duplicate number
      const extension = match[2] || ""; // Handles files with or without extension
      // Adjust the replacement to include a space before the number
      newFileName = fileName.replace(regex, `${num})${extension}`);
    } else {
      const dotIndex = fileName.lastIndexOf(".");
      if (dotIndex !== -1) {
        // Insert "(1)" with a space before it, for files with an extension
        newFileName =
          fileName.substring(0, dotIndex) +
          " (1)" +
          fileName.substring(dotIndex);
      } else {
        // For files without an extension, append " (1)" at the end
        newFileName = fileName + " (1)";
      }
    }
    await createFile({ duplicate: true, fetch: false, newFileName });
  };

  const hideButtons = () => {
    setButtonsPosition({ x: 0, y: 0, visible: false });
  };

  function getFirstTagContent(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const firstElement = doc.body.firstChild;
    return firstElement ? firstElement.textContent : null;
  }

  const saveFile = async (show) => {
    try {
      const response = await fetchDataPost(
        "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/filedirectory/saveeskritordocument",
        {
          application: "eskritor",
          platform: "web",
          hashed_id: hashedId,
          order_id: orderId.current,
          tid: "#Eskritor#" + skRef.current,
          file_name: fileName,
        }
      );
      if (response.ok) {
        const data = await response.json();
        const url_to_upload = data.url_to_upload;
        let tmpContent = null;
        setFileHtmlContent((prevContent) => {
          tmpContent = JSON.parse(JSON.stringify(prevContent));
          return prevContent;
        });

        const result = await axios.put(url_to_upload, tmpContent, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (result.status === 200) {
          lastSavedVersion.current = JSON.parse(JSON.stringify(tmpContent));
          localStorage.setItem("unsaved", false);
          if (show) {
            showToast(t("File saved successfully"), "success");
          }
        } else {
        }
      }
      return true;
    } catch (error) {
      console.error("Failed to save file: ", error);
      return true;
    }
  };

  const createFile = async ({ duplicate, fetch, newFileName, content }) => {
    try {
      let body = {
        application: "eskritor",
        platform: "web",
        hashed_id: hashedId,
        file_name: newFileName,
        language: contentLanguage.current,
      };
      if (format) {
        body.format = format;
      }
      if (tone) {
        body.tone = tone;
      }
      const response = await fetchDataPost(
        "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/filedirectory/createeskritordocument",
        body
      );
      if (response.ok) {
        const data = await response.json();
        const presignedUrl = data.presigned_url;
        const tmpContent = content || fileHtmlContent;
        const result = await axios.put(
          presignedUrl,
          tmpContent,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.status === 200) {
          if (fetch) {
            skRef.current = data.sk.replace("#Eskritor#", "");
            getOrderInfo(skRef.current);
            navigate(`/editor/${skRef.current}/${hashedId}`, { replace: true });
          }
          if (duplicate) {
            showToast(t("File duplicated successfully"), "success");
          } else {
            showToast(t("File created successfully"), "success");
          }
          if (content) {
            localStorage.setItem("unsaved", false);
          }
        } else {
          if (duplicate) {
            showToast(t("Failed to duplicate file"), "error");
          } else {
            showToast(t("Failed to create file"), "error");
          }
        }
      }
    } catch (error) {
      console.error("Failed to create file: ", error);
      showToast(t("Failed to create file"), "error");
    }
  };

  const getHtmlStream = async (
    raw_text,
    type,
    format,
    length,
    tone,
    title,
    summary,
    editWholeDoc
  ) => {
    if (editWholeDoc) {
      selectAllDocument();
    }
    const uri =
      "wss://osvt7t1iik.execute-api.eu-central-1.amazonaws.com/production/";
    webSocket.current = new WebSocket(uri);
    let currentText = "";

    let pre_sentences = "";
    let update_sentences = "";
    let post_sentences = "";

    if (editWholeDoc) {
      update_sentences = fileHtmlContent;
    } else {
      if (generatedTextToBeImproved && generatedTextToBeImproved !== "") {
        update_sentences = generatedTextToBeImproved;
      } else {
        update_sentences = selectedText;
      }
    }

    if (type === "edit") {
      pre_sentences = editWholeDoc ? null : preceding_html.current;
      post_sentences = editWholeDoc ? null : following_html.current;
      isCreateGeneration.current = false;
    } else {
      isCreateGeneration.current = true;
    }
    setWaitingStreamResponse(true);

    var request_data = {
      action:
        process.env.REACT_APP_MODE === "development"
          ? "eskritorCoreWebBeta"
          : process.env.REACT_APP_MODE === "beta"
          ? "eskritorCoreWebBeta"
          : "eskritorCoreWeb",
      command: raw_text || summary,
      generation_type: type,
      hashed_id: hashedId,
      app: "eskritor",
      platform: "web",
      order_id: orderId.current,
      format: format,
      length: length,
      tone: tone,
      language: contentLanguage.current,
      title: title,
    };

    if (type === "edit" && update_sentences) {
      request_data["update_sentences"] = update_sentences;
    }

    if (type === "edit" && post_sentences) {
      request_data["post_sentences"] = post_sentences;
    }

    if (type === "edit" && pre_sentences) {
      request_data["pre_sentences"] = pre_sentences;
    }

    webSocket.current.onopen = () => {
      streamInProgress.current = true;
      console.log("WebSocket connection established");
      webSocket.current.send(JSON.stringify(request_data));
      console.log("> Request sent:", request_data);
      autoScroll.current = true;
    };

    webSocket.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(response);

      if (response.status_code) {
        if (response.status_code !== 200) {
          showToast(response.message, "error");
          setContentLoading(false);
          setWaitingStreamResponse(false);
          return;
        }
      }

      if (response.text) {
        setWaitingStreamResponse(false);

        currentText += response.text;
        if (type === "create") {
          setFileHtmlContent(currentText);
        } else {
          setGeneratedText(currentText);
        }

        if (autoScroll.current) {
          if (type === "create") {
            const editor = quillRef.current?.getEditor();
            if (editor) {
              const scrollElement = editor.scrollingContainer;
              if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
              }
            }
          } else {
            const chatBox = generatedTextRef.current;
            if (chatBox) {
              chatBox.scrollTop = chatBox.scrollHeight;
            }
          }
        }
      }

      if (
        response.message === "Generation has ended, close connection!!!" ||
        stopStreamRef.current
      ) {
        let newContent;
        setFileHtmlContent((prevContent) => {
          newContent = JSON.parse(JSON.stringify(prevContent));
          return prevContent;
        });
        if (firstContent.current) {
          createFile({
            duplicate: false,
            fetch: true,
            newFileName: getFirstTagContent(newContent),
            content: newContent,
          });
          setFileName(getFirstTagContent(newContent));
          firstContent.current = false;
        }
        if (type === "edit") {
          generatedTexts.unshift({ text: currentText, prompt: raw_text });
          if (generatedTexts.length > 5) {
            generatedTexts.pop();
          }
        }
        getStatus();
        setFinishedStream(true);
        streamInProgress.current = false;
        console.log("Special condition met, closing connection.");
        webSocket.current.close(); // Close the connection gracefully
        console.log("Connection closed.");
        stopStreamRef.current = false;
      }
    };

    webSocket.current.onerror = (event) => {
      streamInProgress.current = false;
      console.error("WebSocket error observed:", event);
    };

    return () => {
      if (webSocket.current.readyState === WebSocket.OPEN) {
        streamInProgress.current = false;
        webSocket.current.close();
      }
    };
  };

  useEffect(() => {
    if (hashedId) get_prompts(true);
  }, [hashedId]);

  const handleClickedPosition = (e) => {
    const xFullView = e.clientX * 1.336 - 83.148;
    const yFullView = e.clientY * 1.386 - 7.415;

    const xMiniView = 1.334 * e.clientX - 80.191;
    const yMiniView = 1.355 * e.clientY - 13.134;

    const newPosition = {
      x: window.innerWidth > 1200 ? xFullView : xMiniView,
      y: window.innerWidth > 1200 ? yFullView : yMiniView,
      visible: true,
    };
    //if there is a selection
    if (showImproveButton) {
      setButtonsPosition(newPosition);
      setShowImproveButton(false);
    } else {
      hideButtons();
    }
  };

  useEffect(() => {
    if (hashedId)
      if (skRef.current || oid) {
        getOrderInfo();
      } else {
        firstContent.current = true;
        if (title && summary) {
          getHtmlStream(null, "create", format, length, tone, title, summary);
        } else {
          setshowInitialModal(true);
          if (initlaPromptInputRef.current) {
            initlaPromptInputRef.current.focus();
          }
        }
      }
  }, [hashedId]);

  const get_prompts = async (refresh) => {
    if (refresh) {
      setPromptsInProgress(true);
    }
    const langCode = localStorage.getItem("dashboard-language");
    let url =
      process.env.REACT_APP_MODE === "development"
        ? "https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/eskritorcore/getprompts?hashed_id="
        : process.env.REACT_APP_MODE === "beta"
        ? "https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/eskritorcore/getprompts?hashed_id="
        : "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/getprompts?hashed_id=";
    url += hashedId;
    url += "&app=eskritor&platform=web&language_code=";
    url += langCode;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      setEditPromptFolders([...data.edit]);
    } else {
      console.log("error");
    }
    setPromptsInProgress(false);
  };

  useEffect(() => {
    if (userData) setUserStatus(userData.SStatus);
  }, [userData]);

  const applyChangesToEditor = (newHtml) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) {
      return;
    }
    const delta = editor.clipboard.convert(newHtml);
    editor.setContents(delta, "user");
  };

  function convertMsToFormattedDate(ms) {
    ms = parseInt(ms);
    const date = new Date(ms);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns a 0-based value
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const onRename = async (tid, uid, name) => {
    setFileName(name);
    const response = await fetchDataPost(
      "https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
      { tid: "#Eskritor#" + tid, cid: "User#" + uid, Tname: name }
    );
    if (response) showToast(t("File renamed"), { type: "success" });
  };

  const shareLink = async () => {
    if (orderId.current === null) {
      return;
    }
    try {
      let baseUrl = window.location.origin;

      let link = baseUrl + "/sharing?oid=" + orderId.current;

      await navigator.clipboard.writeText(link);

      showToast(t("File link copied to clipboard"));

      return true;
    } catch (err) {
      console.error("Failed to copy text: ", err);
      return false;
    }
  };

  useEffect(() => {
    const handleCopy = (e) => {
      if (userStatus && userStatus === "Trial") {
        e.preventDefault();
        showModal("UPGRADE-DOWNLOAD");
      }
    };

    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("copy", handleCopy); // Cleanup the event listener on component unmount
    };
  }, [userStatus]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours}:${min.toString().padStart(2, "0")}:${sec
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `${min}:${sec.toString().padStart(2, "0")}`;
    }
  };

  const downloadAsTextFile = () => {
    eskritorExportFile("txt");
    return;
  };

  const downloadAsWordFile = () => {
    eskritorExportFile("docx");
    return;
  };

  const handleLanguageChange = (lang) => {
    contentLanguage.current = lang;
    fetchDataPost(
      "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/seteskritordocumentlanguage",
      {
        new_language: lang,
        platform: "web",
        hashed_id: hashedId,
        pk: "User#" + hashedId,
        sk: "#Eskritor#" + skRef.current,
      }
    );
  };

  const eskritorExportFile = async (type) => {
    const res = await saveFile();
    if (!res) {
      return;
    }
    let url =
      process.env.REACT_APP_MODE === "development"
        ? "https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/fileexport/dispatcher?app=eskritor"
		:process.env.REACT_APP_MODE === "beta"
        ? "https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/fileexport/dispatcher?app=eskritor"
        :"https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/fileexport/dispatcher?app=eskritor";
    url +=
      "&export_type=" +
      type +
      "&hashed_id=" +
      hashedId +
      "&order_id=" +
      orderId.current;

    const response = await fetch(url);

    if (response.ok) {
      const downloadUrl = await response.text();
      console.log(downloadUrl);
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = downloadUrl;
      downloadLink.download = `${fileName}.${type}`;
      downloadLink.click();
      document.body.removeChild(downloadLink);
      hideModal();
    }
  };
  const downloadAsPDFFile = async () => {
    eskritorExportFile("pdf");
    return;
  };

  const copyToClipboard = () => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const text = editor.getText();
      navigator.clipboard.writeText(text);
      hideModal();
      showToast(t("Content copied to clipboard"), "success");
    }
  };

  const calculateCredit = (text) => {
    const credit = Math.ceil(text.length / 100000);
    setGenerationCredit(credit);
  };

  const downloadAsHTMLFile = () => {
    const start =
      "<!DOCTYPE html><html><head><meta charset='utf-8'></head><body>";
    const end = "</body></html>";
    const html = start + fileHtmlContent + end;

    const blob = new Blob([html], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = url;
    downloadLink.download = `${fileName}.html`;
    downloadLink.click();

    document.body.removeChild(downloadLink);
    hideModal();
  };

  const getHtmlContent = (fileContent) => {
    let html = "";
    let isULOpen = false;
    let isOLOpen = false;

    fileContent.forEach((line) => {
      switch (line.tag) {
        case 0: // Paragraph
          if (isULOpen || isOLOpen) {
            html += isULOpen ? "</ul>" : "</ol>";
            isULOpen = isOLOpen = false;
          }
          html += `<p>${line.text}</p>`;
          break;
        case "<h1>": // H1
          if (isULOpen || isOLOpen) {
            html += isULOpen ? "</ul>" : "</ol>";
            isULOpen = isOLOpen = false;
          }
          html += `<h1>${line.text}</h1>`;
          break;
        case "<h2>": // H2
          if (isULOpen || isOLOpen) {
            html += isULOpen ? "</ul>" : "</ol>";
            isULOpen = isOLOpen = false;
          }
          html += `<h2>${line.text}</h2>`;
          break;
        case "<h3>": // H3
          if (isULOpen || isOLOpen) {
            html += isULOpen ? "</ul>" : "</ol>";
            isULOpen = isOLOpen = false;
          }
          html += `<h3>${line.text}</h3>`;
          break;
        case "<ul>": // UL
          if (!isULOpen) {
            if (isOLOpen) {
              html += "</ol>";
              isOLOpen = false;
            }
            html += "<ul>";
            isULOpen = true;
          }
          html += `<li>${line.text}</li>`;
          break;
        case "<ol>": // OL
          if (!isOLOpen) {
            if (isULOpen) {
              html += "</ul>";
              isULOpen = false;
            }
            html += "<ol>";
            isOLOpen = true;
          }
          html += `<li>${line.text}</li>`;
          break;
        default:
          if (isULOpen || isOLOpen) {
            html += isULOpen ? "</ul>" : "</ol>";
            isULOpen = isOLOpen = false;
          }
          html += `<p>${line.text}</p>`;
      }
    });

    // Close any open list tags at the end of the content
    if (isULOpen) html += "</ul>";
    if (isOLOpen) html += "</ol>";

    return html;
  };

  const checkUser = async () => {
    const response = await fetchData(
      "https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" +
        hashedId
    );
    if (response.SStatus === "Trial") {
      showModal("UPGRADE-DOWNLOAD");
    } else {
      showModal("DOWNLOAD-FILE", {
        downloadAsTextFile,
        downloadAsWordFile,
        downloadAsPDFFile,
        copyToClipboard,
        downloadAsHTMLFile,
      });
    }
  };

  const getOrderInfo = async (argSk) => {
    if (!argSk) {
      setContentLoading(true);
    }

    const tmpUid = uid || hashedId;
    try {
      const pk = "User%23" + tmpUid;
      const tmpsk = "%23Eskritor%23" + (skRef.current || argSk);
      var tmpUrl = `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/filedirectory/geteskritorcontent?pk=${pk}&sk=${tmpsk}&application=eskritor&platform=web`;
      if (oid) {
        tmpUrl =
          "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/filedirectory/geteskritorcontent?order_id=" +
          oid +
          "&application=eskritor&platform=web";
      }
      const res = await fetch(tmpUrl);
      if (res.ok) {
        const result = await res.json();

        setFileName(result?.file_name);
        contentLanguage.current =
          result?.language === "" ? contentLanguage.current : result?.language;

        orderId.current = result?.order_id;

        setDate(convertMsToFormattedDate(result?.date));
        const downloadUrl = result?.download_url;
        console.log(downloadUrl);
        if (downloadUrl && !argSk) {
          const response = await fetch(downloadUrl);
          if (response.ok) {
            const result = await response.json();
            let res = result.html_content || result.sentences || result;
            let htmlResponse = res;
            if (Array.isArray(res)) {
              htmlResponse = getHtmlContent(res);
            } else {
              lastSavedVersion.current = JSON.parse(JSON.stringify(res));
              localStorage.setItem("unsaved", false);
            }

            setFileHtmlContent(htmlResponse);
          }
        }
      }
      setContentLoading(false);
    } catch (error) {
      console.log(error);
      setContentLoading(false);
      showToast(t("Failed to get file content."), "error");
      navigate("/documents");
    }
  };

  const onAcceptChanges = (leftSide) => {
    const editor = quillRef.current.getEditor();
    const range = selectedRange;

    if (!editor || !range) {
      console.error("Invalid editor or range");
      return;
    }

    const editorLength = editor.getLength();
    if (range.index > editorLength - 1) {
      console.error("Range index exceeds editor content length");
      return;
    }

    const actualLength = Math.min(range.length, editorLength - range.index);

    var htmlRange = getHtmlRange(selectedText, fileHtmlContent);

    if (!htmlRange) {
      // add tothe end of the file
      htmlRange = { index: fileHtmlContent.length, length: 0 };
    }

    let tmpText;
    if (leftSide) {
      tmpText = generatedTextToBeImproved;
    } else {
      tmpText = generatedText;
    }

    const newHtml = selectedPreText + tmpText + selectedPostText;
    console.log(newHtml);
    applyChangesToEditor(newHtml);
    setGeneratedText("");
    setSelectedText("");
    setGeneratedTextToBeImproved("");
    setSelectedPreText("");
    setSelectedPostText("");
  };

  const getHtmlRange = (searchText, htmlContent) => {
    if (htmlContent.includes(searchText)) {
      const index = htmlContent.indexOf(searchText);
      const length = searchText.length;
      return { index, length };
    } else {
      const searchWithotuTags = searchText
        .replace(/^<[^>]+>/, "")
        .replace(/<\/[^>]+>$/, "");
      if (htmlContent.includes(searchWithotuTags)) {
        const index = htmlContent.indexOf(searchWithotuTags);
        const length = searchWithotuTags.length;
        return { index, length };
      } else {
        return null;
      }
    }
  };

  const setEditLinesData = (range) => {
    var range = range;
    var editor = quillRef.current.getEditor();
    var fullText = editor.getText();

    // Function to expand selection to full sentences
    function expandToFullSentences(range, text) {
      const sentenceEndRegex = /[.!?]|\s*[\r\n]+\s*/g;
      let start = range.index,
        end = range.index + range.length;
      const selection = text.substring(start, end);
      let lastEnd = 0;
      sentenceEndRegex.lastIndex = 0;
      while (true) {
        const match = sentenceEndRegex.exec(text);
        if (!match) break;
        if (match.index + match[0].length > start) {
          start = lastEnd;
          break;
        }
        lastEnd = match.index + match[0].length;
      }

      const trimmedText = selection.trim();
      if (
        trimmedText[trimmedText.length - 1] === "." ||
        trimmedText[trimmedText.length - 1] === "!" ||
        trimmedText[trimmedText.length - 1] === "?" ||
        trimmedText[trimmedText.length - 1] === "\n"
      ) {
        return { index: start, length: end - start };
      }

      sentenceEndRegex.lastIndex = lastEnd;
      while (true) {
        const match = sentenceEndRegex.exec(text);
        if (!match) {
          end = text.length;
          break;
        }
        if (match.index >= end) {
          end = match.index + match[0].length;
          break;
        }
      }

      return { index: start, length: end - start };
    }

    // Expand range to include full sentences
    var expandedRange = expandToFullSentences(range, fullText);

    // Extract the text for the adjusted range
    var selectedContent = editor.getContents(
      expandedRange.index,
      expandedRange.length
    );
    var selectedPreContent = editor.getContents(0, expandedRange.index);
    var selectedPostContent = editor.getContents(
      expandedRange.index + expandedRange.length,
      fullText.length - (expandedRange.index + expandedRange.length)
    );
    var tempContainer = document.createElement("div");
    var tempQuill = new Quill(tempContainer);
    tempQuill.setContents(selectedContent);
    var tmpText = tempContainer.querySelector(".ql-editor").innerHTML;
    setSelectedText(tmpText);
    setGenerationCredit(calculateCredit(tmpText));
    setSelectedRange(expandedRange);
    var preContainer = document.createElement("div");
    var preQuill = new Quill(preContainer);
    preQuill.setContents(selectedPreContent);
    var preText = preContainer.querySelector(".ql-editor").innerHTML;
    var postContainer = document.createElement("div");
    var postQuill = new Quill(postContainer);
    postQuill.setContents(selectedPostContent);
    var postText = postContainer.querySelector(".ql-editor").innerHTML;
    setSelectedPostText(postText);
    setSelectedPreText(preText);

    console.log("Preceding Text:", preText);
    console.log("Following Text:", postText);

    // Helper function to extract HTML from a range
    function extractHTMLFromRange(index, length) {
      if (length <= 0) return "";
      var content = editor.getContents(index, length);
      var container = document.createElement("div");
      var quill = new Quill(container);
      quill.setContents(content);
      return container.querySelector(".ql-editor").innerHTML;
    }

    let preSentences = [];
    let sentenceBoundRegex = /[.!?]|\s*[\r\n]+\s*/g;
    let lastEnd = 0;

    let match;
    // Collecting sentences before the selected text
    while (
      (match = sentenceBoundRegex.exec(fullText)) &&
      match.index + match[0].length <= expandedRange.index
    ) {
      preSentences.push(lastEnd);
      lastEnd = match.index + match[0].length;
    }

    // Extract up to 6 sentences or as many as available before the selection
    let preHTML = "";
    if (preSentences.length > 0) {
      let preStartIndex = Math.max(0, preSentences.length - 6);
      preHTML = extractHTMLFromRange(
        preSentences[preStartIndex],
        expandedRange.index - preSentences[preStartIndex]
      );
    }

    // Collecting sentences after the selected text
    let postStart = expandedRange.index + expandedRange.length;
    let postSentences = [];
    sentenceBoundRegex.lastIndex = postStart; // Start searching from the end of the expanded range
    while (
      (match = sentenceBoundRegex.exec(fullText)) &&
      postSentences.length < 6
    ) {
      postSentences.push(match.index + match[0].length);
    }

    let postHTML =
      postSentences.length > 0
        ? extractHTMLFromRange(
            postStart,
            postSentences[postSentences.length - 1] - postStart
          )
        : "";

    preceding_html.current = preHTML;
    following_html.current = postHTML;
    //console.log("Preceding Sentences HTML:", preHTML);
    //console.log("Selected Sentences HTML:", tmpText);
    //console.log("Following Sentences HTML:", postHTML);
  };

  const onImproveClicked = (e) => {
    e.stopPropagation();

    setShowCompareModal(true);
    hideButtons();
    setGeneratedText("");
    setGeneratedTexts([]);
  };

  const onStopStream = () => {
    stopStreamRef.current = true;
  };

  const onLanguageChange = (lang) => {
    contentLanguage.current = lang;
  };

  const onWriteNewClick = () => {
    setshowInitialModal(true);
  };
  return (
    <>
      <SettingsModal
        showSettingsModal={showSettingsModal}
        setShowSettingsModal={setShowSettingsModal}
        createFile={createFile}
        autoSaveEnabled={autoSaveEnabled}
        setAutoSaveEnabled={setAutoSaveEnabled}
        duplicateFile={duplicateFile}
        contentLanguage={contentLanguage}
        handleLanguageChange={handleLanguageChange}
      />
      <Modal
        show={showInitialModal}
        onHide={() => setshowInitialModal(false)}
        centered
      >
        <Modal.Body className="">
          <PromptGenerator
            minutes={minutes}
            onLanguageChange={onLanguageChange}
            onWrite={({ format, tone, length, title, summary }) => {
              setshowInitialModal(false);
              getHtmlStream(
                null,
                "create",
                format,
                length,
                tone,
                title,
                summary
              );
            }}
            initialLanguage={contentLanguage.current}
          />
        </Modal.Body>
      </Modal>
      {buttonsPosition.visible && (
        <div
          className="buttonsContainer"
          style={{
            position: "fixed",
            zIndex: 40,
            left: `${buttonsPosition.x}px`,
            top: `${buttonsPosition.y}px`,
          }}
        >
          <button
            class="btn theme-btn theme-btn min-btn-width "
            onClick={onImproveClicked}
          >
            <span className="me-2" style={{ backgroundColor: "transparent" }}>
              <svg
                viewBox="0 0 24 24"
                className="command-svg"
                width="16px"
                height="16px"
              >
                <g>
                  <rect fill="none" height="16px" width="16px" x="0"></rect>
                </g>
                <g>
                  <g>
                    <path
                      fill="currentColor"
                      d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
            {t("Improve")}
          </button>
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card card-padding overflow-visible">
            <div className="row align-items-center">
              <div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
                <h2 class="small-title regular mb-2">
                  <span class="me-2 burger-web-view">
                    <img
                      src={fileMark}
                      class="img-fluid"
                      height="auto"
                      width="28px"
                      alt="img"
                    />
                  </span>
                  {!isMobileDevice() && (
                    <span
                      class="me-2 burger-mobile-view"
                      onClick={() => handleOpenSidebar()}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        class="text-black"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 12H20.25"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M3.75 6H20.25"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M3.75 18H20.25"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                  )}
                  {fileName && (
                    <div className="filename-container">
                      <span className="filename">{fileName}</span>
                      <Link
                        onClick={() =>
                          showModal(
                            "RENAME",
                            {
                              name: fileName,
                              tid: skRef.current,
                              uid: hashedId,
                            },
                            { onRename }
                          )
                        }
                        className="web-view"
                      >
                        <span
                          class="icons-hover"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={t("Rename")}
                          data-bs-original-title="Rename"
                        >
                          <img src={rename} class="img-fluid" alt="img" />
                        </span>
                      </Link>
                    </div>
                  )}
                </h2>
              </div>
              {/*	<div className="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3 text-center">
								<div className="toggle-container d-flex align-items-center justify-content-center">
									<span>{t("Manual")}</span>
									<label className="switch mx-2">
										<input type="checkbox" onChange={handleToggleChange} checked={editorMode === "ai"} />
										<span className="slider round"></span>
									</label>
									<span>{t("AI")}</span>
								</div>
							</div> */}
              <div class="col-xxl-8 col-lg-8 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate">
                <div class="file-action-icon-flex mb-3">
                  {streamInProgress.current && isCreateGeneration.current ? (
                    <div className="text-center">
                      <button
                        class="btn theme-btn white-btn-bg me-3  "
                        onClick={onStopStream}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-stop-circle me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5z" />
                        </svg>
                        {t("Stop")}
                      </button>
                    </div>
                  ) : (
                    <button
                      class="btn theme-btn white-btn-bg  me-2 mt-2 mb-2"
                      style={{ padding: "4px" }}
                      onClick={onWriteNewClick}
                    >
                      <Icon class="me-2" path={mdiCreationOutline} size={1.2} />
                      {t("Write New")}
                    </button>
                  )}

                  <div className="d-flex align-items-center">
                    <svg
                      viewBox="0 0 24 24"
                      className="command-svg me-2 mb-2"
                      width="25px"
                      height="25px"
                    >
                      <g>
                        <rect fill="none" height="20" width="20" x="0"></rect>
                      </g>
                      <g>
                        <g>
                          <path
                            fill="#FF981A"
                            d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"
                          ></path>
                          <path
                            fill="#FF981A"
                            d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"
                          ></path>
                          <path
                            fill="#FF981A"
                            d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"
                          ></path>
                          <path
                            fill="#FF981A"
                            d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <p
                      class=" yellow-logo-text mb-0 me-3"
                      style={{ fontSize: "20px" }}
                    >
                      {minutes} {t("AI Credits")}{" "}
                    </p>
                  </div>
                  <Link
                    onClick={() => setShowSettingsModal(true)}
                    className={`hover-effect`}
                  >
                    <span
                      class="file-action-list"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={t("Settings")}
                    >
                      <span className="hover-span">{t("Settings")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        class="bi bi-gear-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                      </svg>
                    </span>
                  </Link>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      checkUser();
                    }}
                    className="hover-effect"
                  >
                    <span class="file-action-list theme-color">
                      <span className="hover-span">{t("Download")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="19.34"
                        viewBox="0 0 21 19.34"
                      >
                        <g
                          id="Group_46"
                          data-name="Group 46"
                          transform="translate(-0.16)"
                        >
                          <rect
                            id="Rectangle_36"
                            data-name="Rectangle 36"
                            width="21"
                            height="15"
                            rx="3"
                            transform="translate(21.16 15.34) rotate(180)"
                            fill="#fff"
                          />
                          <rect
                            id="Rectangle_37"
                            data-name="Rectangle 37"
                            width="21"
                            height="5"
                            rx="2.5"
                            transform="translate(21.16 17.34) rotate(180)"
                            fill="currentColor"
                          />
                          <rect
                            id="Rectangle_38"
                            data-name="Rectangle 38"
                            width="21"
                            height="5"
                            rx="2.5"
                            transform="translate(21.16 19.34) rotate(180)"
                            fill="#fff"
                          />
                          <g id="arrow-small-down" transform="translate(5.402)">
                            <path
                              id="Path_163"
                              data-name="Path 163"
                              d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
                              transform="translate(-11.25 -15)"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </Link>
                  <Link
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default action.
                      onSave(true);
                    }}
                    className="hover-effect"
                  >
                    <span class="file-action-list theme-color" ref={saveRef}>
                      <span className="hover-span">{t("Save")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.5"
                        height="20.535"
                        viewBox="0 0 20.5 20.535"
                      >
                        <g
                          id="Group_47"
                          data-name="Group 47"
                          transform="translate(0 0)"
                        >
                          <rect
                            id="Rectangle_39"
                            data-name="Rectangle 39"
                            width="20.5"
                            height="20.5"
                            rx="5"
                            transform="translate(0 0.035)"
                            fill="#f9fcff"
                          />
                          <path
                            id="Rectangle_40"
                            data-name="Rectangle 40"
                            d="M4,0H6.66a4,4,0,0,1,4,4V6.56a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                            transform="translate(4.92 10.695)"
                            fill="currentColor"
                          />
                          <path
                            id="Rectangle_41"
                            data-name="Rectangle 41"
                            d="M0,0H10.66a0,0,0,0,1,0,0V2.56a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V0A0,0,0,0,1,0,0Z"
                            transform="translate(4.92 0.035)"
                            fill="currentColor"
                          />
                          <rect
                            id="Rectangle_42"
                            data-name="Rectangle 42"
                            width="4.1"
                            height="4.938"
                            rx="2.05"
                            transform="matrix(1, -0.017, 0.017, 1, 9.43, 0.072)"
                            fill="#f9fcff"
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                  <Link
                    class="share_btn hover-effect"
                    onClick={(e) => {
                      e.preventDefault();
                      shareLink();
                    }}
                  >
                    <span class="file-action-list">
                      <span className="hover-span">{t("Share")}</span>
                      <img src={share} class="img-fluid" alt="img" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <hr class="mt-0" />
            <div>
              <ul
                class="nav nav-pills btn-pills mb-3 notranslate"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation"></li>
              </ul>
              <div
                class="tab-content file-prompt-container"
                id="pills-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="pills-traskript"
                  role="tabpanel"
                  aria-labelledby="pills-traskript-tab"
                >
                  {contentLoading ? (
                    <div className="card-padding loading-view">
                      <br />
                      <div className="d-flex justify-content-center text-center">
                        <span class="spinner-border text-primary"></span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={`editor-layout d-flex flex-row`}>
                        <SideDiv
                          minutes={minutes}
                          editPromptFolders={editPromptFolders}
                          setEditPromptFolders={setEditPromptFolders}
                          get_prompts={get_prompts}
                          getStream={getHtmlStream}
                          onAcceptChanges={onAcceptChanges}
                          fileHtmlContent={fileHtmlContent}
                          waitingStreamResponse={waitingStreamResponse}
                          generatedText={generatedText}
                          generatedTexts={generatedTexts}
                          selectedText={selectedText}
                          streamInProgress={streamInProgress}
                          setGeneratedText={setGeneratedText}
                          setGeneratedTexts={setGeneratedTexts}
                          generatedTextRef={generatedTextRef}
                          showCompareModal={showCompareModal}
                          setShowCompareModal={setShowCompareModal}
                          autoScroll={autoScroll}
                          setSelectedRange={setSelectedRange}
                          setSelectedText={setSelectedText}
                          onStopStream={onStopStream}
                          generatedTextToBeImproved={generatedTextToBeImproved}
                          setGeneratedTextToBeImproved={
                            setGeneratedTextToBeImproved
                          }
                          setShowSettingsModal={setShowSettingsModal}
                          setSelectedPostText={setSelectedPostText}
                          setSelectedPreText={setSelectedPreText}
                          generationCredit={generationCredit}
                        />
                        <div
                          onClick={handleClickedPosition}
                          ref={fileViewRef}
                          className={`file-view dark-color ${
                            showCompareModal ? "d-none" : ""
                          }`}
                          style={{
                            height: showPromptsBox ? "00px" : "650px",
                            direction:
                              contentLanguage.current === "ar" ||
                              contentLanguage.current === "fa" ||
                              contentLanguage.current === "he"
                                ? "rtl"
                                : "ltr",
                          }}
                        >
                          {waitingStreamResponse &&
                          isCreateGeneration.current ? (
                            <div className="d-flex justify-content-center text-center">
                              <span class="spinner-border text-primary"></span>
                            </div>
                          ) : (
                            <RichTextEditor
                              fileHtmlContent={fileHtmlContent}
                              setFileHtmlContent={setFileHtmlContent}
                              quillRef={quillRef}
                              onSelectionChange={onSelectionChange}
                              autoScroll={autoScroll}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentEditor;
