import { Modal } from "react-bootstrap";
import { useTranslation } from "../../components/common/LanguageProvider";
import { useState } from "react";
import duplicate from '../../assets/img/files/duplicate.svg';
import { Link } from "react-router-dom";
import ContentLanguageDropdown from "../Dashboard/ContentLanguageDropdown";
import { fetchDataPost } from "../../hooks/apiUtils";
import DashboardLanguageDropdown from "../../components/common/dashboardLanguageDropdown";


function SettingsModal({ showSettingsModal, setShowSettingsModal, duplicateFile, autoSaveEnabled, setAutoSaveEnabled, contentLanguage, handleLanguageChange}) {
    const { t } = useTranslation();


    const handleToggleChange = (event) => {

        setAutoSaveEnabled(event.target.checked);
        localStorage.setItem('autoSaveEnabled', event.target.checked);
    };

  
    return (
        <Modal show={showSettingsModal} onHide={() => setShowSettingsModal(false)} centered>
            <Modal.Body>
                <button type="button" class="btn-close" onClick={() => setShowSettingsModal(false)} aria-label="Close"></button>
                <h2 class="mini-title text-center mb-4">{t("Settings")}</h2>
                <div className='d-flex justify-content-between'>
                    <label className='form-group-label'>{t("Auto save")}</label>
                    <div className="toggle-container d-flex align-items-center justify-content-center">
                        <label className="switch mx-2">
                            <input type="checkbox" onChange={handleToggleChange} checked={autoSaveEnabled} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class=" d-flex justify-content-between align-items-center mt-3">

                    <label className='form-group-label'>{t("Writing Language")}</label>
                    <div style={{ width: '300px' }}>
                        <ContentLanguageDropdown language={contentLanguage.current} onLanguageChange={handleLanguageChange} hideBorder={true}/>
                    </div>
                </div>
                <div class=" d-flex justify-content-between align-items-center mt-3">

                    <label className='form-group-label'>{t("Dashboard Language")}</label>
                    <div style={{ width: '300px' }}>
                    <DashboardLanguageDropdown onLanguageChange={() => {}} />
                    </div>
                </div>
               
                <Link onClick={duplicateFile} className={`hover-effect`}>

                <div class=" d-flex flex-column  mt-3">
                    <span
                        class="file-action-list"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t("Duplicate")}
                    >
                        <span className="hover-span">
                            {t("Duplicate")}
                        </span>
                        <img src={duplicate} class="img-fluid" alt="img" />
                    </span>
                    <label className='form-group-label'>{t("Duplicate")}</label>

                </div>
                </Link>
            </Modal.Body>
        </Modal>
    )
}

export default SettingsModal;