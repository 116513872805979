import React, {useState, useRef} from "react";

import LanguageDropdown from "../components/common/transcribeLanguageDropdown";

import googleMeet from "../assets/img/icon/google-meet.svg";
import microsoftTeams from "../assets/img/icon/microsoft-teams.svg";
import zoom from "../assets/img/icon/zoom.svg";
import botIcon from "../assets/img/icon/bot-btn-icon.svg";
import meetingBot111 from "../assets/img/meeting-bot/1-1.1.png";
import meetingBot112 from "../assets/img/meeting-bot/1-1.2.png";
import meetingBot113 from "../assets/img/meeting-bot/1-1.3.png";
import meetingBot114 from "../assets/img/meeting-bot/1-1.4.png";
import meetingBot121 from "../assets/img/meeting-bot/1-2.1.png";
import meetingBot122 from "../assets/img/meeting-bot/1-2.2.png";
import meetingBot123 from "../assets/img/meeting-bot/1-2.3.png";
import meetingBot131 from "../assets/img/meeting-bot/1-3.1.png";
import meetingBot132 from "../assets/img/meeting-bot/1-3.2.png";
import meetingBot133 from "../assets/img/meeting-bot/1-3.3.png";
import meetingBot134 from "../assets/img/meeting-bot/1-3.4.png";
import meetingBot135 from "../assets/img/meeting-bot/1-3.5.png";
import meetingBot211 from "../assets/img/meeting-bot/2-1.1.png";
import meetingBot212 from "../assets/img/meeting-bot/2-1.2.png";
import meetingBot213 from "../assets/img/meeting-bot/2-1.3.png";
import meetingBot214 from "../assets/img/meeting-bot/2-1.4.png";
import meetingBot215 from "../assets/img/meeting-bot/2-1.5.png";
import meetingBot216 from "../assets/img/meeting-bot/2-1.6.png";
import meetingBot217 from "../assets/img/meeting-bot/2-1.7.png";
import meetingBot218 from "../assets/img/meeting-bot/2-1.8.png";
import meetingBot219 from "../assets/img/meeting-bot/2-1.9.png";
import meetingBot2110 from "../assets/img/meeting-bot/2-1.10.png";
import meetingBot2111 from "../assets/img/meeting-bot/2-1.11.png";
import meetingBot2112 from "../assets/img/meeting-bot/2-1.12.png";
import meetingBot2113 from "../assets/img/meeting-bot/2-1.13.png";
import meetingBot221 from "../assets/img/meeting-bot/2-2.1.png";
import meetingBot222 from "../assets/img/meeting-bot/2-2.2.png";
import meetingBot223 from "../assets/img/meeting-bot/2-2.3.png";
import meetingBot224 from "../assets/img/meeting-bot/2-2.4.png";
import meetingBot225 from "../assets/img/meeting-bot/2-2.5.png";
import meetingBot226 from "../assets/img/meeting-bot/2-2.6.png";
import meetingBot227 from "../assets/img/meeting-bot/2-2.7.png";
import meetingBot228 from "../assets/img/meeting-bot/2-2.8.png";
import meetingBot231 from "../assets/img/meeting-bot/2-3.1.png";
import meetingBot232 from "../assets/img/meeting-bot/2-3.2.png";
import meetingBot233 from "../assets/img/meeting-bot/2-3.3.png";
import meetingBot234 from "../assets/img/meeting-bot/2-3.4.png";
import meetingBot235 from "../assets/img/meeting-bot/2-3.5.png";
import meetingBot236 from "../assets/img/meeting-bot/2-3.6.png";
import meetingBot237 from "../assets/img/meeting-bot/2-3.7.png";
import meetingBot238 from "../assets/img/meeting-bot/2-3.8.png";
import meetingBot239 from "../assets/img/meeting-bot/2-3.9.png";
import { useAuth } from "../Auth/AuthContext";
import {fetchDataPost} from "../hooks/apiUtils";
import { showToast } from "../components/common/toast";
import { useTranslation } from "../components/common/LanguageProvider";

function MeetingAssistant() {

    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [selectedService, setSelectedService] = useState();
	const [meetingUrl, setMeetingUrl] = useState();
	const meetingUrlRef = useRef();
	const {hashedId} = useAuth();
	const { t } = useTranslation();

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

	const handleMeeting = async(url)=>{
		setIsLoading(true);
		setMeetingUrl(url);
		let api_url = "https://9367iu6ljh.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-MeetingBot-MeetingHandler";
		let body = {
		  "meetingUrl": url,
		  "uid":hashedId,
		  "service":"8",
		  "language":selectedLanguage,
		  "meetingType":"instant",
		  "timezone": "tz"
		}
		showToast(t("The request has been sent. Please wait..."), {type:"success"});
		const response = await fetchDataPost(api_url, body);
		const data = await response.json();
		console.log(data);
		var message = data.message;
		var status = data.status;
		let texts = [
			"Meeting link is invalid. Please check the link again.",
			"There is already Transkriptor Meeting Assistant in this meeting.",
			"Meeting Assistant will join the meeting in after a while.",
			"Unknown error occurred. Please contact support."
		  ]
		  if(message === "Meeting link is invalid" ){
			showToast(t(texts[0]), {type:"error"});
		  }else if(message === "Bot is already in this meeting."){
			showToast(t(texts[1]), {type:"error"});
		  }else if(message === "Successfully added meeting url"){
			showToast(t(texts[2]), {type:"success"});
		  }
		  if(status === 500){
			showToast(t(texts[3]), {type:"success"});
		  }
		setMeetingUrl("");
		setIsLoading(false);
	}
    return (
        <>
        <div class="row text-center">
		<p className="text-center w-80">{t("Please add your meeting URL or invite Transkriptor Bot to the meeting, make sure that your target language is correct, afterwards accept bot to the meeting in Zoom, Microsoft Teams or Google Meet. Finally, wait for the transcription after the meeting ends.")}</p>
      <div className="d-flex justify-content-center">
	  <div class="col-lg-6 card-margin">
					<div class="card card-padding card-100 text-center overflow-visible">
						<p class="ft-18-title">{t("Share your Meeting URL with Transkriptor Meeting Assistant")}</p>
						<p>{t("Share meeting URL below and wait for the Transkriptor Meeting Assistant to join the meeting")}</p>
						<div class="img-icon-list">
							<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Meet"><img src={googleMeet} class="img-fluid" alt="img"/></span>
							<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Microsoft Teams"><img src={microsoftTeams} class="img-fluid" alt="img"/></span>
							<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img"/></span>
						</div>
						<div className='row m-2'style={{ display: isLoading ? "block" : "none" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className="spinner-border text-primary" role="status">

                                    </div>
                                </div>


                        </div>       
						<div class="input-group mb-3">
						  	<input type="text" class="form-control round-input border-input" placeholder={t("Enter URL")} aria-label="Recipient's username" aria-describedby="basic-addon2" value={meetingUrl} ref={meetingUrlRef} onChange={(e)=> setMeetingUrl(e.target.value)} onKeyDown={(e)=>{
								if(e.key === "Enter" && !isLoading){
									e.preventDefault();
									handleMeeting(e.target.value)
								}
							}} />
								<span 
									class={`input-group-text btn theme-btn rounded-btn ${isLoading || !meetingUrl? "disabled-btn" : ""}`} 
									onClick={isLoading ? null : () => handleMeeting(meetingUrl)} 
									id="basic-addon2"
								>
									<span class="me-2">
										<img src={botIcon} class="img-fluid" width="15px" alt="img"/>
									</span>
									{t("Add Assistant")}
								</span>
						</div>
						<div class="form-group selectBox border-input round-input">
                                <LanguageDropdown onLanguageChange={handleLanguageChange} />
							    
							</div>
					</div>
			</div>
	  </div>
            <div class="col-12 sec-pad-top">
					<h3 class="medium-title text-center mb-lg-5 mb-4">{t("Frequently Asked Questions")}</h3>
					<div class="card card-padding card-margin">
						<div class="accordion accordion-flush" id="accordionFlushExample">
							<div class="accordion-item ">
								<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingOne">
						      		<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
						        		{t("How to share meeting URL to add the Transkriptor Meeting Assistant to your meeting?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseOne" class="accordion-collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
						    		<div class="accordion-body">
						      			<ul class="nav nav-pills icon-tab mb-3" id="pills-tab" role="tablist">
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link active" id="pills-bot1-1-tab" data-bs-toggle="pill" data-bs-target="#pills-bot1-1" type="button" role="tab" aria-controls="pills-bot1-1" aria-selected="true">
										    		<img src={zoom} class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link" id="pills-bot1-2-tab" data-bs-toggle="pill" data-bs-target="#pills-bot1-2" type="button" role="tab" aria-controls="pills-bot1-2" aria-selected="false">
										    		<img src={googleMeet} class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link" id="pills-bot1-3-tab" data-bs-toggle="pill" data-bs-target="#pills-bot1-3" type="button" role="tab" aria-controls="pills-bot1-3" aria-selected="false">
										    		<img src={microsoftTeams} class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										</ul>
										<div class="tab-content dark-p" id="pills-tabContent">
										  	<div class="tab-pane fade show active" id="pills-bot1-1" role="tabpanel" aria-labelledby="pills-bot1-1-tab">
								  				<ol>
								  					<li>
								  						<p>{t("1. Click 'More Options for Participants'")}</p>
								    					<img src={meetingBot111} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("2. Click 'Copy Invite Link'")}</p>
								    					<img src={meetingBot112}class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("3. Click URL and paste the meeting link and click 'Add Assistant' the Transkriptor Meeting Assistant will join the meeting soon.")}</p>
								    					<img src={meetingBot113} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("4. Admit Transkriptor Meeting Assistant to the meeting.")}</p>
								    					<img src={meetingBot114}class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										  	<div class="tab-pane fade" id="pills-bot1-2" role="tabpanel" aria-labelledby="pills-bot1-2-tab">
										  		<ol>
								  					<li>
								  						<p>{t("1. Click 'Copy to CLIPBOARD'")}</p>
								    					<img src={meetingBot121} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("2. Click URL and paste the copied URL, then click 'Add Assistant', the Transkriptor Meeting Assistant will join soon.")}</p>
								    					<img src={meetingBot122} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("3. Click 'Admit' to the meeting for Transkriptor and Transkriptor Meeting Assistant will join the meeting, as soon as no one left in the meeting, it will start transcribing.")}</p>
								    					<img src={meetingBot123} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										  	<div class="tab-pane fade" id="pills-bot1-3" role="tabpanel" aria-labelledby="pills-bot1-3-tab">
										  		<ol>
								  					<li>
								  						<p>{t("1. Click 'Show participants'")}</p>
								    					<img src={meetingBot131} class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("2. Click 'Share invite'")}</p>
								    					<img src={meetingBot132}  class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("3. Click 'Copy meeting link'")}</p>
								    					<img src={meetingBot133}  class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("4. Click URL and paste your URL, then click 'Add Assistant'")}</p>
								    					<img src={meetingBot134}  class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>{t("5. Click the button and add Transkriptor to the meeting")}</p>
								    					<img src={meetingBot135}  class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										</div>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item" style={{display: "none"}}>
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingTwo">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
						        		How to invite the Transkriptor Bot to your meeting?
						      		</button>
						    	</h2>
						    	<div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<ul class="nav nav-pills icon-tab mb-3" id="pills-tab" role="tablist">
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link active" id="pills-bot2-1-tab" data-bs-toggle="pill" data-bs-target="#pills-bot2-1" type="button" role="tab" aria-controls="pills-bot2-1" aria-selected="true">
										    		<img src="assets/img/icon/zoom.svg" class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link" id="pills-bot2-2-tab" data-bs-toggle="pill" data-bs-target="#pills-bot2-2" type="button" role="tab" aria-controls="pills-bot2-2" aria-selected="false">
										    		<img src="assets/img/icon/google-meet.svg" class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										  	<li class="nav-item" role="presentation">
										    	<button class="nav-link" id="pills-bot2-3-tab" data-bs-toggle="pill" data-bs-target="#pills-bot2-3" type="button" role="tab" aria-controls="pills-bot2-3" aria-selected="false">
										    		<img src="assets/img/icon/microsoft-teams.svg" class="img-fluid" alt="img"/>
										    	</button>
										  	</li>
										</ul>
										<div class="tab-content dark-p" id="pills-tabContent">
										  	<div class="tab-pane fade show active" id="pills-bot2-1" role="tabpanel" aria-labelledby="pills-bot2-1-tab">
								  				<ol>
								  					<li>
								  						<p>Open Zoom</p>
								  					</li>
								  					<li>
								  						<p>Click 'New Meeting'</p>
								    					<img src="assets/img/meeting-bot/2-1.1.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Join with Computer Audio'</p>
								    					<img src="assets/img/meeting-bot/2-1.2.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Manage Participants'</p>
								    					<img src="assets/img/meeting-bot/2-1.3.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Invite or Alt+I'</p>
								    					<img src="assets/img/meeting-bot/2-1.4.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Email'</p>
								    					<img src="assets/img/meeting-bot/2-1.5.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Default Email'</p>
								    					<img src="assets/img/meeting-bot/2-1.6.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'To:'</p>
								    					<img src="assets/img/meeting-bot/2-1.7.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Type 'bot@transkriptor.com'</p>
								    					<img src="assets/img/meeting-bot/2-1.8.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Transkriptor Bot bot@transkriptor.com'</p>
								    					<img src="assets/img/meeting-bot/2-1.9.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Send'</p>
								    					<img src="assets/img/meeting-bot/2-1.10.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Participants' and wait for the Transkriptor Bot to join</p>
								    					<img src="assets/img/meeting-bot/2-1.11.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Admit' to meeting for Transkriptor</p>
								    					<img src="assets/img/meeting-bot/2-1.12.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'End' or Press 'Alt+Q' to leave/end the meeting and wait for the transcription</p>
								    					<img src="assets/img/meeting-bot/2-1.13.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										  	<div class="tab-pane fade" id="pills-bot2-2" role="tabpanel" aria-labelledby="pills-bot2-2-tab">
										  		<ol>
										  			<li>
								  						<p>Open Google Meet</p>
								  					</li>
								  					<li>
								  						<p>Click 'New Meeting'</p>
								    					<img src="assets/img/meeting-bot/2-2.1.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Start an instant meeting'</p>
								    					<img src="assets/img/meeting-bot/2-2.2.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Add People'</p>
								    					<img src="assets/img/meeting-bot/2-2.3.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Type 'bot@transkriptor.com'</p>
								    					<img src="assets/img/meeting-bot/2-2.4.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Add recipient'</p>
								    					<img src="assets/img/meeting-bot/2-2.5.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Send email'</p>
								    					<img src="assets/img/meeting-bot/2-2.6.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Admit' to add the Transkriptor Bot to the meeting</p>
								    					<img src="assets/img/meeting-bot/2-2.7.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'End' after meeting has ended and wait for the transcription</p>
								    					<img src="assets/img/meeting-bot/2-2.8.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										  	<div class="tab-pane fade" id="pills-bot2-3" role="tabpanel" aria-labelledby="pills-bot2-3-tab">
										  		<ol>
								  					<li>
								  						<p>Click 'New meeting' on Calendar in Teams</p>
								    					<img src="assets/img/meeting-bot/2-3.1.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Add required attendees'</p>
								    					<img src="assets/img/meeting-bot/2-3.2.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Type 'bot@transkriptor.com'</p>
								    					<img src="assets/img/meeting-bot/2-3.3.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Select 'Transkriptor Bot'</p>
								    					<img src="assets/img/meeting-bot/2-3.4.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Send'</p>
								    					<img src="assets/img/meeting-bot/2-3.5.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Join' to join the meeting</p>
								    					<img src="assets/img/meeting-bot/2-3.6.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Join now'</p>
								    					<img src="assets/img/meeting-bot/2-3.7.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Wait for the Transkriptor Bot and Click 'Admit' to add it to the meeting</p>
								    					<img src="assets/img/meeting-bot/2-3.8.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  					<li>
								  						<p>Click 'Leave' to end the meeting and wait for the transcription</p>
								    					<img src="assets/img/meeting-bot/2-3.9.png" class="img-fluid mb-3" alt="img"/>
								  					</li>
								  				</ol>
										  	</div>
										</div>
						    		</div>
						    	</div>
						  	</div>
						</div>
					</div>
				</div>
        </div>
        </>
    );
}

export default MeetingAssistant;