import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./config";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithCustomToken,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showToast } from "../components/common/toast";
import Gleap from "gleap";
import countryLanguage from "country-language";
import { fetchData } from "../hooks/apiUtils";
import { useTranslation } from "../components/common/LanguageProvider";
import {
  popularLanguages,
  allLanguages,
} from "../components/common/transcribeLanguageDropdown";

import { sendAmplitudeData } from "./amplitude";
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null); // [name, email, uid
  const [hashedId, setHashedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const auth = getAuth();
  auth.useDeviceLanguage();
  const navigate = useNavigate();
  const path = useLocation().pathname;

  useEffect(() => {
    getStatus();
  }, [location, hashedId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  const getStatus = async () => {
    if (hashedId) {
      const response = await fetchData(
        "https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" +
          hashedId
      );
      setUserData(response);
    }
  };
  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function sendResetPasswordMail(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function sendVerification(user) {
    return sendEmailVerification(user);
  }

  function signInWithToken(token) {
    return signInWithCustomToken(auth, token);
  }

  function googleLogin() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }
  function updateProfileName(name) {
    return updateProfile(auth.currentUser, { displayName: name });
  }
  const automateLogin = async (uid, redirect) => {
    const response = await fetch(
      "https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" +
        uid
    );

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
      const token = data["customToken"];
      await signInWithToken(token);
      console.log("keke");
      if (redirect) {
        console.log(redirect);
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    } else {
      console.log("Error:", response.status, response.statusText);
    }

    console.log("kek");
  };
  function removeQueryParameters() {
    // Navigate without reloading the page
    navigate(path, { replace: true });
  }
  const sendExtensionData = async (user, hashedId) => {
    try {
      let language = localStorage.getItem("dashboard-language");
      if (window.chrome && window.chrome.runtime) {
        let widgetData = JSON.stringify({
          userId: "User#" + hashedId,
          email: user.email,
          language: language,
        });

        window.chrome.runtime.sendMessage(localStorage.getItem("extId"), {
          action: "loginUser",
          widgetData,
          language,
        });
      }
    } catch (error) {
      console.log("Error accessing chrome runtime:", error);
    }
  };
  const findLanguage = async () => {
    try {
      const browserLanguage =
        navigator.language || navigator.userLanguage || "en-US";
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || "US");
      localStorage.setItem("transcript-language", browserLanguage);
      window.location.reload();
    } catch (error) {
      console.error("Error detecting browser language:", error);
    }
  };
  const directLogin = async (token) => {
    return signInWithToken(token);
  };
  const fetchLanguages = async (temp_hashedId, segment) => {
    if (!temp_hashedId) return;
    try {
      const queryParams = new URLSearchParams({
        user_id: temp_hashedId,
        dashboard_language: localStorage.getItem("dashboard-language") || "en",
        app_name: "eskritor",
      }).toString();
      const response = await fetch(
        `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem(
          "user_locales",
          JSON.stringify(data.suggested_dashboard_languages)
        );
        localStorage.setItem(
          "all_locales",
          JSON.stringify(data.dashboard_languages)
        );
      } else {
        console.error("Failed to fetch languages:", response.statusText);
        localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
        localStorage.setItem("all_locales", JSON.stringify(allLanguages));
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
      localStorage.setItem("all_locales", JSON.stringify(allLanguages));
    }
  };

  async function fetchDataWithTimeout(url, timeout = 10000) {
    const timeoutPromise = new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject("Request timed out");
      }, timeout);
    });

    try {
      const startTime = performance.now();
      const responsePromise = await fetchData(url);
      const endTime = performance.now();
      const requestDuration = endTime - startTime;
      console.log("Second request duration:", requestDuration);
      const response = await Promise.race([responsePromise, timeoutPromise]);
      return response;
    } catch (error) {
      console.error("Error or timeout:", error);
      throw error; // Rethrow to handle it in the calling function
    }
  }

  async function handleRedirectToTheProd(temp_hashedId) {
    return;
    const website_url = window.location.href;
    const urlObj = new URL(website_url);

    // Get the origin which includes protocol and host (domain and port if applicable)
    const base_website_url = urlObj.origin;

    const params = {
      product_name: "eskritor",
      beta_url: base_website_url,
    };

    // Construct the full URL with query parameters
    const queryParams = new URLSearchParams(params).toString();
    console.log(55124124, queryParams);
    const baseUrl = "https://d10qm7q3ql133b.cloudfront.net";
    const fullUrl = `${baseUrl}?${queryParams}`;
    const response = await fetchData(fullUrl);
    console.log(23, response, response.prod_url, temp_hashedId);
    if (response.prod_url) {
      window.location.href = response.prod_url + "/signIn?uid=" + temp_hashedId; // Redirect to the URL
    }
  }

  async function handleRedirectToTheBeta(temp_hashedId) {
    const cf_url = `https://d10qm7q3ql133b.cloudfront.net?product_name=eskritor`;

    try {
      // Measure the duration of the first request
      const startTime = performance.now();
      const response = await fetchData(cf_url);
      const endTime = performance.now();
      const requestDuration = endTime - startTime;

      console.log(
        23,
        response.is_testing,
        currentUser,
        response.is_testing === true
      );
      console.log("First request duration:", requestDuration);

      // Check the duration of the first request
      if (requestDuration <= 150 && response.is_testing === true) {
        let hashed_id = temp_hashedId; // Ensure temp_hashedId is defined elsewhere
        const http_url = `https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/webapptesting/checkisuserintest?product_name=eskritor&hashed_id=${hashed_id}`;
        // const cf_url2 = `https://dhhka5k6ov9s1.cloudfront.net?product_name=eskritor&hashed_id=${hashed_id}`;

        // Second request with 1 second timeout
        try {
          const response2 = await fetchDataWithTimeout(http_url, 1000);
          console.log(23, response2.beta_url);

          if (response2.beta_url) {
            window.location.href = `${response2.beta_url}/signIn?uid=${hashed_id}`; // Redirect to the URL
          }
        } catch (error) {
          console.error("Failed to fetch or timeout on second request:", error);
        }
      } else {
        console.log("First request took too long, skipping second request.");
      }
    } catch (error) {
      console.error("Failed on first request:", error);
    }
  }

  useEffect(() => {
    // This is a listener which Firebase provides to determine
    // authentication state. It's automatically managed, so if the token
    // is invalidated or expired, the user will be logged out automatically.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      console.log(user);
      if (user) {
        if (user.emailVerified) {
          const queryParams = new URLSearchParams(location.search);
          // To retrieve a specific query parameter:
          const option = queryParams.get("option");
          const start = queryParams.get("start");
          const lang = queryParams.get("lang");

          if (option) {
            if (option === "transkriptor") {
              sessionStorage.setItem("transkriptor-redirect", true);
              removeQueryParameters();
            }
          }
          const isGoogleSignUp = user.providerData?.some(
            (provider) => provider.providerId === "google.com"
          );
          const currentTimestamp = Date.now();
          const creationTimestamp = user.metadata.createdAt;
          console.log("user is logged in");
          fetch(
            "https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid=" +
              user.uid +
              "&app=Eskritor"
          )
            .then((response) => response.json())
            .then(async (data) => {
              console.log(data);
              const temp_hashedId = data.hashedId;
              const is_new_user = data.is_new_user;

              console.log("LOGGED IN HERE");

              if (process.env.REACT_APP_MODE === "production") {
                handleRedirectToTheBeta(temp_hashedId);
              } else if (process.env.REACT_APP_MODE === "beta") {
                handleRedirectToTheProd(temp_hashedId);
              }

              setHashedId(data.hashedId);
              fetch(
                "https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" +
                  data.hashedId
              )
                .then((response) => response.json())
                .then((data) => {
                  setUserData(data);
                  let segment = data.UserSegment;

                  fetchLanguages(temp_hashedId, segment);

                  if (
                    isGoogleSignUp &&
                    parseInt(currentTimestamp) - parseInt(creationTimestamp) <
                      180000 &&
                    localStorage.getItem("google-clicked") === "true" &&
                    is_new_user
                  ) {
                    localStorage.setItem("google-clicked", false);
                    sendAmplitudeData(
                      "sign_up",
                      data,
                      user,
                      "google_sign_up_button",
                      "app.eskritor.com/signIn",
                      "app.eskritor.com/dashboard"
                    );
                  } else if (
                    !isGoogleSignUp &&
                    localStorage.getItem("signedup-flag") === "true" &&
                    is_new_user
                  ) {
                    localStorage.setItem("signedup-flag", false);
                    sendAmplitudeData(
                      "sign_up",
                      data,
                      user,
                      "sign_up_button",
                      "app.eskritor.com/register",
                      "app.eskritor.com/dashboard"
                    );
                  }
                  if (
                    isGoogleSignUp &&
                    localStorage.getItem("google-clicked") === "true"
                  ) {
                    localStorage.setItem("google-clicked", false);
                    sendAmplitudeData(
                      "sign_in",
                      data,
                      user,
                      "google_sign_in_button",
                      "app.eskritor.com/signIn",
                      "app.eskritor.com/dashboard"
                    );
                  } else if (localStorage.getItem("signedin-flag") === "true") {
                    localStorage.setItem("signedin-flag", false);
                    sendAmplitudeData(
                      "sign_in",
                      data,
                      user,
                      "sign_in_button",
                      "app.eskritor.com/signIn",
                      "app.eskritor.com/dashboard"
                    );
                  }

                  const queryParams = new URLSearchParams(location.search);
                  // Get the user agent string
                  var userAgentString = navigator.userAgent;
                  if (!localStorage.getItem("transcript-language")) {
                    findLanguage();
                  } else {
                    sendExtensionData(user, temp_hashedId);
                  }
                  // Detect the browser name
                  var browserName = "";
                  if (userAgentString.indexOf("Chrome") != -1) {
                    browserName = "Chrome";
                  } else if (userAgentString.indexOf("Firefox") != -1) {
                    browserName = "Firefox";
                  } else if (userAgentString.indexOf("Safari") != -1) {
                    browserName = "Safari";
                  } else if (
                    userAgentString.indexOf("Opera") != -1 ||
                    userAgentString.indexOf("OPR") != -1
                  ) {
                    browserName = "Opera";
                  } else if (userAgentString.indexOf("Edge") != -1) {
                    browserName = "Edge";
                  } else if (
                    userAgentString.indexOf("MSIE") != -1 ||
                    !!document.documentMode == true
                  ) {
                    browserName = "Internet Explorer";
                  } else {
                    browserName = "Unknown browser";
                  }
                  Gleap.identify(
                    "User#" + temp_hashedId,
                    {
                      email: data.Mail,
                      customData: {
                        subscription: data.SStatus,
                        segment: data.UserSegment,
                        language: localStorage.getItem("dashboard-language"),
                        browser: browserName,
                      },
                    },
                    temp_hashedId
                  );
                  // To retrieve a specific query parameter:
                  const uid = queryParams.get("uid");
                  const redirect = queryParams.get("redirect");
                  if (redirect) {
                    navigate("/dashboard");
                  } else {
                    if (
                      path === "/signIn" ||
                      path === "/register" ||
                      path === "/"
                    ) {
                      navigate("/dashboard");
                    }
                  }
                });
            });
        } else {
          sendVerification(user);
          showToast(t("Please check your mailbox to verify your email."), {
            type: "success",
            position: "top-center",
          });

          logout();

          if (
            path !== "/signIn" &&
            path !== "/register" &&
            !path.startsWith("/authentication")
          ) {
            navigate("/signIn");
            console.log(
              "user is not logged in and path is not /signIn or /register"
            );
          }
        }
      } else {
        console.log("user is not logged in");
        const queryParams = new URLSearchParams(location.search);
        // To retrieve a specific query parameter:
        const uid = queryParams.get("uid");
        const redirect = queryParams.get("redirect");
        const token = queryParams.get("t");
        if (token) {
          directLogin(token);
        }
        if (
          !redirect &&
          path !== "/signIn" &&
          path !== "/register" &&
          !path.startsWith("/authentication")
        ) {
          navigate("/signIn");
          console.log(
            "user is not logged in and path is not /signIn or /register"
          );
        } else if (uid && redirect) {
          navigate("/dashboard");
          automateLogin(uid, redirect);
        }
      }
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    hashedId,
    userData,
    signup,
    getStatus,
    login,
    logout,
    sendResetPasswordMail,
    sendVerification,
    signInWithToken,
    googleLogin,
    updateProfileName,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
