import { Modal } from "react-bootstrap";
import { useTranslation } from "../../components/common/LanguageProvider";
import { useEffect, useRef, useState } from "react";
import duplicate from '../../assets/img/files/duplicate.svg';
import { Link } from "react-router-dom";
import ContentLanguageDropdown from "../Dashboard/ContentLanguageDropdown";
import { fetchDataPost } from "../../hooks/apiUtils";
import DashboardLanguageDropdown from "../../components/common/dashboardLanguageDropdown"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import QuillToolbar, { formats, modules } from "./toolbar";
import './editor.css';



function RichTextEditor({ fileHtmlContent, setFileHtmlContent, quillRef, onSelectionChange, autoScroll }) {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  var index = 0;
  useEffect(() => {
    quillRef.current?.editor.root.setAttribute("spellcheck", "false");

  }, []);

  useEffect(() => {
    setCharCount(quillRef.current?.editor.getLength() - 1);
    setWordCount(quillRef.current?.editor.getText().split(/\s+/).length - 1);
  }, [fileHtmlContent]);


  const handleScroll = (event) => {
    const scrollElement = event.target;
    const isAtBottom = scrollElement.scrollHeight - (scrollElement.scrollTop + scrollElement.clientHeight) <= 1;
    autoScroll.current = isAtBottom;
  };

  useEffect(() => {
    const quillInstance = quillRef.current;
    if (quillInstance) {
      const editor = quillInstance.getEditor();
      const scrollElement = editor.scrollingContainer;
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [quillRef]);


  return (
    <div
      ref={containerRef}
    >
      <QuillToolbar wordCount={wordCount} charCount={charCount} />
      <ReactQuill
        ref={quillRef}

        theme="snow"
        value={fileHtmlContent}
        onChange={setFileHtmlContent}
        modules={modules}
        formats={formats}
        onChangeSelection={onSelectionChange}
      />

    </div>
  )
}

export default RichTextEditor;


/*
import { Modal } from "react-bootstrap";
import { useTranslation } from "../../components/common/LanguageProvider";
import { useEffect, useRef, useState } from "react";
import duplicate from '../../assets/img/files/duplicate.svg';
import { Link } from "react-router-dom";
import ContentLanguageDropdown from "../Dashboard/ContentLanguageDropdown";
import { fetchDataPost } from "../../hooks/apiUtils";
import DashboardLanguageDropdown from "../../components/common/dashboardLanguageDropdown"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import QuillToolbar, { formats, modules } from "./toolbar";
import './editor.css';
import eskritoLetter from '../../assets/img/eskritor-letter-white.png';


function RichTextEditor({ fileHtmlContent, setFileHtmlContent, quillRef, onSelectionChange, autoScroll }) {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  var index = 0;
  const [showPopup, setShowPopup] = useState(false);
  const circleRef = useRef(null);
  useEffect(() => {
    quillRef.current?.editor.root.setAttribute("spellcheck", "false");

  }, []);

  useEffect(() => {
    setCharCount(quillRef.current?.editor.getLength() - 1);
    setWordCount(quillRef.current?.editor.getText().split(/\s+/).length - 1);
  }, [fileHtmlContent]);


  const handleScroll = (event) => {
    const scrollElement = event.target;
    const isAtBottom = scrollElement.scrollHeight - (scrollElement.scrollTop + scrollElement.clientHeight) <= 1;
    autoScroll.current = isAtBottom;

    updateCirclePosition();
  };

  useEffect(() => {
    const quillInstance = quillRef.current;
    if (quillInstance) {
      const editor = quillInstance.getEditor();
      const scrollElement = editor.scrollingContainer;
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [quillRef]);
  const updateCirclePosition = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection) {
      const bounds = editor.getBounds(selection.index, selection.length);
      const editorRect = containerRef.current.getBoundingClientRect();
      const circle = circleRef.current;
      const verticalCenter = bounds.top + bounds.height / 2;
      circle.style.top = `${verticalCenter}px`;
    }
  };

  return (

    <div
      ref={containerRef}
    >
      <QuillToolbar wordCount={wordCount} charCount={charCount} />
      <div className="enhanced-editor ">
        <div
          ref={circleRef}
          className="circle"
          onClick={() => setShowPopup(!showPopup)}
        >
          <span
            style={{
              cursor: 'pointer',
              padding: '12px',
            }}
          >
            <img src={eskritoLetter} className="img-fluid" alt="img" />
          </span>
        </div>

        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={fileHtmlContent}
          onChange={setFileHtmlContent}
          modules={modules}
          formats={formats}
          onChangeSelection={(e) => {
            //  onSelectionChange(e)
            updateCirclePosition()
          }
          }
        />
      </div>
    </div>
  )
}

export default RichTextEditor;

*/