import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDirection } from '../../components/common/DirectionContext';
import { showToast } from '../../components/common/toast';
import { useTranslation, loadLanguageTranslations } from '../../components/common/LanguageProvider';
import { useAuth } from '../../Auth/AuthContext';


function ContentLanguageDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { setDirection } = useDirection();
    const dropdownRef = useRef(null);
    // New state variable for the filtered languages
    const { t } = useTranslation();
    const storedLocales = localStorage.getItem("user_locales") !== null && localStorage.getItem("user_locales") !== "undefined" ? (JSON.parse(localStorage.getItem("user_locales")) || popularLanguages) : popularLanguages;
    const storedAllLocales = localStorage.getItem("all_locales") !== null && localStorage.getItem("all_locales") !== "undefined" ? (JSON.parse(localStorage.getItem("all_locales")) || languages) : languages;
    const [filteredPopularLanguages, setFilteredPopularLanguages] = useState(storedLocales);
    const [filteredAllLanguages, setFilteredAllLanguages] = useState(storedAllLocales);
    const activeRef = useRef(null)
    const { hashedId } = useAuth();

    const handleLanguageClick = (event) => {
        const language = event.target;
        if (language) {

            setSelectedLanguage(language.innerHTML);
            localStorage.setItem("writing_language", language.getAttribute('value'));
            setIsOpen(false);
            props.onLanguageChange(language.getAttribute('value'))

            filterLanguages(""); // Clear the input value when a language is selected

        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        } else {
            setIsOpen(true)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const findLanguage = (langKey) => {

        // 1. Try the full key (e.g., en-US)
        console.log("langKey: " + langKey);

        // Split and get just the language part
        const langOnly = langKey.split('-')[0];
        console.log("langOnly: " + langOnly);

        // 2. Check the uppercase (e.g., TR)
        if (languages[langOnly.toUpperCase()]) {
            return languages[langOnly.toUpperCase()];
        }
        console.log("languages[langOnly.toUpperCase()]: " + languages[langOnly.toUpperCase()]);
        // 3. Try lowercase (e.g., en)
        if (languages[langOnly.toLowerCase()]) {
            return languages[langOnly.toLowerCase()];
        }

        // 4. Check for the full langKey
        if (languages[langKey]) {
            return languages[langKey];
        }

        if (Object.values(languages).includes(langKey)) {
            const langCode = Object.keys(languages).find(key => languages[key] === langKey);
            localStorage.setItem("writing_language", langCode);
            props.onLanguageChange(langCode)
            return languages[langCode];
        }

        // 5. Default to English (EN)
        return languages["en"];
    };
    const [inputValue, setInputValue] = useState(""); // To store the characters entered by the user
    const [selectedLanguage, setSelectedLanguage] = useState(findLanguage(props.language || localStorage.getItem("writing_language") || localStorage.getItem("dashboard-language") || "en"));


    const filterLanguages = (input) => {
        setInputValue(input);
        const inputLower = input.toLowerCase();

        const popularFiltered = Object.entries(storedLocales).filter(([_, name]) => name.toLowerCase().includes(inputLower));
        const allFiltered = Object.entries(storedAllLocales).filter(([_, name]) => name.toLowerCase().includes(inputLower));


        setFilteredPopularLanguages(Object.fromEntries(popularFiltered));
        setFilteredAllLanguages(Object.fromEntries(allFiltered));
    };




    return (
        <>
            <div ref={dropdownRef} className={`selectBox ${isOpen ? 'show' : ''}`} >
                <div
                    className={`form-control round-input  selectBox__value ${props.hideBorder ? '' : 'border-input'}`}
                    tabIndex="0" // Makes the div focusable 
                >
                    {selectedLanguage}
                </div>

                {isOpen && (
                    <div class={`dropdown-menu ${props.upward ? 'select-top-dropdown-menu' : ''}` }>
                        <div className="language-group">
                            <div class="mb-2 table-search input-icon-div d-lg-inline-block d-block ms-auto mt-lg-0 mt-3">
                                <input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={inputValue} onChange={(e) => filterLanguages(e.target.value)} />
                                <span class="input-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                            <h5 className="label-translate m-2">{t("Popular Languages")}</h5>

                            {Object.entries(filteredPopularLanguages).map(([code, label], index) => (
                                <Link
                                    ref={selectedLanguage === label ? activeRef : null}

                                    className={`dropdown-item ${selectedLanguage === label ? "active" : ""
                                        }`}
                                    value={code}
                                    onClick={handleLanguageClick}
                                >
                                    {label}
                                </Link>
                            ))
                            }
                        </div>
                        <div className="language-group">
                            <h5 className="label-translate m-2">All Languages</h5>
                            {Object.entries(filteredAllLanguages).map(([code, label], index) => (
                                <Link
                                    ref={selectedLanguage === label ? activeRef : null}

                                    className={`dropdown-item ${selectedLanguage === label ? "active" : ""
                                        }`}
                                    value={code}
                                    onClick={handleLanguageClick}
                                >
                                    {label}
                                </Link>
                            ))}
                        </div>

                    </div>
                )}
            </div>

        </>
    );
}

export default ContentLanguageDropdown;

const popularLanguages =
{
    "pt": "Portuguese (Português)",
    "en": "English (English)",
    "es": "Spanish (Español)",
    "tr": "Turkish (Türkçe)",
    "de": "German (Deutsch)",
    "it": "Italian (Italiano)",
    "ar": "Arabic (عربي)",
    "fr": "French (Français)",
    "id": "Indonesian ",
    "ko": "Korean (한국인)",
    "ja": "Japanese (日本)",
    "zh": "Chinese (中国人)",
    "hi": "Hindi (हिन्दी)",
    "ru": "Russian (Русский)",
    "th": "Thai (ไทย)",
    "vi": "Vietnamese ",
}


const languages =
{
    "af": "Afrikaans (Afrikaans)",
    "am": "Amharic (አማርኛ)",
    "ar": "Arabic (عربي)",
    "bn": "Bengali (বুটে)",
    "bg": "Bulgarian (Български)",
    "my": "Burmese (မြန်မာနိုင်ငံ)",
    "ca": "Catalan (Calalana)",
    "zh": "Chinese (中国人)",
    "hr": "Croatian (Hrvatski)",
    "cs": "Czech (Čeština)",
    "da": "Danish (Dansk)",
    "nl": "Dutch (Nederlands)",
    "en": "English (English)",
    "et": "Estonian (Eesti)",
    "fil": "Filipino (Pilipino)",
    "fi": "Finnish (Suomalainen)",
    "fr": "French (Français)",
    "de": "German (Deutsch)",
    "el": "Greek (Ελληνικά)",
    "gu": "Gujarati (ગુજરાત)",
    "he": "Hebrew (עִברִית)",
    "hi": "Hindi (हिन्दी)",
    "hu": "Hungarian (Magyar)",
    "is": "Icelandic (Íslenskt)",
    "id": "Indonesian ",
    "ga": "Irish (Gaeilge)",
    "it": "Italian (Italiano)",
    "ja": "Japanese (日本)",
    "jv": "Javanese (Basa Jawa)",
    "kn": "Kannada (ಕನ್ನಡ)",
    "km": "Khmer (ខ្មែរ)",
    "ko": "Korean (한국인)",
    "lo": "Lao (ພາສາລາວ)",
    "lv": "Latvian (Latviski)",
    "lt": "Lithuanian (Lietuvių)",
    "mk": "Macedonian ",
    "ms": "Malay (Melayu)",
    "mt": "Maltese (Maltaca)",
    "mr": "Marathi (मराठी)",
    "nb": "Norwegian (Norsk)",
    "fa": "Persian (فارسی)",
    "pl": "Polish (Dialekt)",
    "pt": "Portuguese (Português)",
    "ro": "Romanian (Română)",
    "ru": "Russian (Русский)",
    "sr": "Serbian (Српски)",
    "si": "Sinhala (සිංහල)",
    "sk": "Slovak (Slovenský)",
    "sl": "Slovenian (Slovenščina)",
    "es": "Spanish (Español)",
    "sw": "Swahili (Svahili)",
    "sv": "Swedish (Svenska)",
    "ta": "Tamil (தமிழ்)",
    "te": "Telugu (తెలుగు)",
    "th": "Thai (ไทย)",
    "tr": "Turkish (Türkçe)",
    "uk": "Ukrainian ",
    "ur": "Urdu (اردو)",
    "uz": "Uzbek (O'Zbek Tili)",
    "vi": "Vietnamese ",
    "zu": "Zulu (Isizulu)"
}