
import React from "react";
import { Quill } from "react-quill";
import './editor.css';
import { useTranslation } from "../../components/common/LanguageProvider";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not

var icons = Quill.import('ui/icons');
icons['header']['1'] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h1" viewBox="0 0 16 16">
<path d="M7.648 13V3H6.3v4.234H1.348V3H0v10h1.348V8.421H6.3V13zM14 13V3h-1.333l-2.381 1.766V6.12L12.6 4.443h.066V13z"/>
</svg>`

icons['header']['2'] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h2" viewBox="0 0 16 16">
<path d="M7.495 13V3.201H6.174v4.15H1.32V3.2H0V13h1.32V8.513h4.854V13zm3.174-7.071v-.05c0-.934.66-1.752 1.801-1.752 1.005 0 1.76.639 1.76 1.651 0 .898-.582 1.58-1.12 2.19l-3.69 4.2V13h6.331v-1.149h-4.458v-.079L13.9 8.786c.919-1.048 1.666-1.874 1.666-3.101C15.565 4.149 14.35 3 12.499 3 10.46 3 9.384 4.393 9.384 5.879v.05z"/>
</svg>`

icons['header']['3'] = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-type-h3" viewBox="0 0 16 16">
<path d="M11.07 8.4h1.049c1.174 0 1.99.69 2.004 1.724s-.802 1.786-2.068 1.779c-1.11-.007-1.905-.605-1.99-1.357h-1.21C8.926 11.91 10.116 13 12.028 13c1.99 0 3.439-1.188 3.404-2.87-.028-1.553-1.287-2.221-2.096-2.313v-.07c.724-.127 1.814-.935 1.772-2.293-.035-1.392-1.21-2.468-3.038-2.454-1.927.007-2.94 1.196-2.981 2.426h1.23c.064-.71.732-1.336 1.744-1.336 1.027 0 1.744.64 1.744 1.568.007.95-.738 1.639-1.744 1.639h-.991V8.4ZM7.495 13V3.201H6.174v4.15H1.32V3.2H0V13h1.32V8.513h4.854V13z"/>
</svg>`


// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}



// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const QuillToolbar = ({ charCount, wordCount }) => {
  const { t } = useTranslation();

  return (

    <div id="toolbar" >
      <span className="ql-formats">
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Bold")} className="ql-bold " />
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Italic")} className="ql-italic" />
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Underline")} className="ql-underline" />
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Strike Through")} className="ql-strike" />
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Header") + ' 1'} className="ql-header" value="1">H1</button>
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Header") + ' 2'} className="ql-header" value="2">H2</button>
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Header") + ' 3'} className="ql-header" value="3">H3</button>
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Ordered List")} className="ql-list" value="ordered" />
        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Unordered List")} className="ql-list" value="bullet" />

        <span className="ql-formats" >
          <button className="ql-undo" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Undo")}>
            <CustomUndo />
          </button>
          <button className="ql-redo" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Redo")}>
            <CustomRedo />
          </button>
        </span>
      </span>


      <span className="stats me-2">
       

        <span className="me-2">

          {wordCount} {t("Words")}
        </span>

        <span >
          {charCount} {t("Characters")}

        </span>
      </span>
    </div>
  )
};

export default QuillToolbar;
