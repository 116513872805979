import React, { useEffect, useRef, useState } from 'react';
import './sideDiv.css'; // Import the CSS file for styling
import { useModal } from '../../components/common/Modal/ModalContext';
import { useAuth } from '../../Auth/AuthContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import { fetchDataPost } from '../../hooks/apiUtils';
import { showToast } from '../../components/common/toast';
import MaxHeightInput from '../../components/common/MaxLineInput';
import Icon from '@mdi/react';
import { mdiAutoMode, mdiCreationOutline } from '@mdi/js';
import undo from '../../assets/img/files/undo.svg';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

function SideDiv({ editPromptFolders, setEditPromptFolders, get_prompts, minutes, getStream, selectedText, generatedText, onAcceptChanges, fileHtmlContent, waitingStreamResponse, generatedTexts, streamInProgress, setGeneratedText, setGeneratedTexts, generatedTextRef, showCompareModal, setShowCompareModal, autoScroll, setSelectedRange, setSelectedText, onStopStream, generatedTextToBeImproved, setGeneratedTextToBeImproved, setShowSettingsModal, setSelectedPreText, setSelectedPostText, generationCredit }) {
    const [openFolders, setOpenFolders] = useState({});
    const { showModal, hideModal } = useModal();
    const { userData, hashedId } = useAuth();
    const { t } = useTranslation();
    const [promptSelected, setPromptSelected] = useState(false);

    const [question, setQuestion] = useState("");
    const [hoveredFolder, setHoveredFolder] = useState(null);
    const [hoveredPrompts, setHoveredPrompts] = useState([]);
    const [promptDivHovered, setPromptDivHovered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const promptTextRef = useRef(null);


    function handleMouseEnter(folderId) {
        setHoveredFolder(folderId);
        setHoveredPrompts(editPromptFolders.find(folder => folder.folder_id === folderId).prompts);
    }


    function handleMouseLeave() {
        setHoveredFolder(null);

    }


    const onPromptMouseDown = (event) => {
        if (selectedText !== "" && !showCompareModal)
            event.preventDefault();
    };
    const onPromptBoxClick = (event) => {
        if (selectedText !== "") {
            setShowCompareModal(true);
            setPromptDivHovered(false)
            promptTextRef.current.focus();
        }
    }
    const toggleFolder = (folderId) => {
        setOpenFolders(prev => ({
            ...prev,
            [folderId]: !prev[folderId]
        }));
    };
    const handleDeletePrompt = async (prompt) => {
        const newPrompts = hoveredPrompts.filter(p => p.prompt_index !== prompt.prompt_index);
        setHoveredPrompts(newPrompts);

        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/deleteprompt", {
            app: "eskritor",
            platform: "web",
            folder_id: prompt.folder_id,
            prompt_index: prompt.prompt_index
        });
        if (response.ok) {
            get_prompts();
            showToast(t("Prompt successfully deleted"));
        }
    }

    const handleEditPrompt = async (newname, newcommand, prompt) => {
        const newPrompts = [...hoveredPrompts];
        const index = newPrompts.findIndex(p => p.prompt_index === prompt.prompt_index);
        newPrompts[index] = { ...prompt, title: newname, command: newcommand };
        setHoveredPrompts(newPrompts);

        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/saveprompt", {
            app: "eskritor",
            platform: "web",
            folder_id: prompt.folder_id,
            command: newcommand,
            title: newname,
            prompt_index: prompt.prompt_index
        });
        if (response.ok) {
            showToast(t("Prompt successfully saved"));
            get_prompts();
        }
    }

    useEffect(() => {
        if (editPromptFolders.length > 0) {
            setOpenFolders({ [editPromptFolders[0].folder_id]: true });
        }
    }, [editPromptFolders]);

    useEffect(() => {
        setGeneratedText(generatedTexts[currentPage - 1]?.text);
    }, [currentPage]);


    const questionSetter = (question, promptSelected) => {
        setPromptSelected(promptSelected);
        setQuestion(question);
    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    };
    const isFolderEditable = (folderId) => {
        const folder = editPromptFolders.find(folder => folder.folder_id === folderId);
        return folder ? !folder.is_default : false;
    };
    const generate_answer = async () => {
        if (minutes < 1) {
            showModal('UPGRADE-GENERATE');
            return;
        }
        else {
            setShowCompareModal(true);
            onEditPrompt();
        }
    };
    const handleToggleChange = (event) => {
        return

    };
    const onEditPrompt = () => {
        const tmpQuestion = question != '' ? question : generatedTexts[0]?.prompt

        getStream(tmpQuestion, "edit", null, null, null, null, null, selectedText === "");
    };

    const onFinishClicked = () => {

        if (waitingStreamResponse || streamInProgress.current) {
            onStopStream();
        }
        setQuestion('');
        if (generatedTextToBeImproved && generatedTextToBeImproved !== '') {
            onAcceptChanges(true);
            setShowCompareModal(false)
            setGeneratedTexts([])
        } else {
            setGeneratedText('')
            setGeneratedTexts([])
            setShowCompareModal(false)
            setSelectedRange(null)
            setSelectedText('')
            setGeneratedText('')
            setSelectedPreText("");
            setSelectedPostText("");
        }


    }

    const handleSavePrompt = async (folderId, title, command) => {

        setEditPromptFolders(prev => {
            let folders = [...prev];

            const newFolders = folders.map(folder => {
                if (folder.folder_id === folderId) {
                    return { ...folder, prompts: [...folder.prompts, { title, command, prompt_index: null, folder_id: folderId }] }
                }
                return folder;
            })
            return newFolders;
        });

        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/saveprompt", {
            app: "eskritor",
            platform: "web",
            folder_id: folderId,
            command: command,
            title: title,
            prompt_index: null
        });
        if (response.ok) {
            get_prompts();
            showToast(t("Prompt saved"));
        } else {
            get_prompts();
            showToast(t("Failed to save prompt"), { type: "error" });
        }
    };

    const handlePromptClick = (prompt) => {
        if (streamInProgress.current || waitingStreamResponse) {
            return;
        }
        if (minutes < 1) {
            showModal('UPGRADE-GENERATE');
            return;
        }
        else {
            setShowCompareModal(true);
            setPromptDivHovered(false)
            let command
            if (prompt.original_title) {
                command = prompt.original_title
            } else {
                command = prompt.command
            }
            getStream(command, "edit", null, null, null, null, null, selectedText === "")
        }
    };
    const handleEditPromptFolder = async (newname, folder_id) => {
        const newFolders = [...editPromptFolders];
        const index = newFolders.findIndex(folder => folder.folder_id === folder_id);
        newFolders[index].folder_name = newname;
        setEditPromptFolders(newFolders);


        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/createpromptfolder", {
            app: "eskritor",
            platform: "web",
            hashed_id: hashedId,
            folder_name: newname,
            folder_id: folder_id,
            folder_type: 'edit',
        });
        if (response.ok) {
            showToast(t("Prompt successfully saved"));
            get_prompts();
        }
    }

    const handleDeletePromptFolder = async (folder_id) => {
        const newFolders = [...editPromptFolders];
        const index = newFolders.findIndex(folder => folder.folder_id === folder_id);
        newFolders.splice(index, 1);
        setEditPromptFolders(newFolders);

        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/deletepromptfolder", {
            application: "eskritor",
            platform: "web",
            folder_id: folder_id,
            hashed_id: hashedId
        });
        if (response.ok) {
            get_prompts();
            showToast(t("Prompt folder successfully deleted"));
        }
    }
    const onCreatePromptFolder = async () => {
        showModal('CREATE-PROMPT-FOLDER', { uid: hashedId }, { onCreateFolder: handleCreateFolder });
    }

    const onPromptChange = (text) => {
        questionSetter(text, false)
    }

    const improveGeneratedText = () => {
        setGeneratedTextToBeImproved(generatedText);
        setGeneratedText('');
        setGeneratedTexts([]);
    }


    const handleCreateFolder = async (uid, folderName, promptCommand = null) => {
        const newFolders = [...editPromptFolders];
        newFolders.unshift({ folder_name: folderName, folder_id: null, folder_type: 'edit', is_default: false });
        setEditPromptFolders(newFolders);

        const response = await fetchDataPost("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/createpromptfolder", {
            hashed_id: uid, application: "eskritor", platform: "web",
            folder_name: folderName,
            folder_type: 'edit',
            folder_id: null
        });
        const data = await response.json();
        const folderId = data.folder_id;
        setEditPromptFolders(newFolders.map(folder => {
            if (folder.folder_id === null) {
                return { ...folder, folder_id: folderId }
            }
            return folder;
        }));
        if (response.ok) {
            get_prompts();
            showToast(t("Folder created"));
            if (promptCommand) {
                showModal('SAVE-PROMPT', { uid: hashedId, onSavePrompt: handleSavePrompt, promptCommand: promptCommand, promptFolders: [{ 'folder_id': folderId, 'folder_name': folderName }] });
            }
        } else {
            get_prompts();
            showToast(t("Failed to create folder"), { type: "error" });
        }
    }

    const onSavePrompt = async (promptCommand, desiredFolder) => {
        const folders = editPromptFolders.filter(folder => !folder.is_default);
        let folderIndex
        if (desiredFolder) {
            folderIndex = folders.findIndex(folder => folder.folder_id === desiredFolder);
        }
        if (folders.length === 0) {
            showModal('FIRST-CREATE-PROMPT-FOLDER', { uid: hashedId }, { onCreateFolder: (uid, folderName) => handleCreateFolder(uid, folderName, promptCommand) });
        } else {
            showModal('SAVE-PROMPT', { uid: hashedId }, { onSavePrompt: handleSavePrompt, promptCommand: promptCommand, promptFolders: folders });
        }
    }
    return (
        <>      {!showCompareModal &&
            <>
                <div className="side-div"
                >
                    <div className="side-div-header">
                        <h2 className="sidebar-title">{t("Edit with AI")}</h2>
                        <button className="" onClick={onCreatePromptFolder} style={{ background: 'none', border: 'none' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF981A" class="bi bi-folder-plus" viewBox="0 0 16 16">
                                <path d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z" />
                                <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5" />
                            </svg>
                        </button>
                    </div>

                    <div className="folders-container"
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        {editPromptFolders.map(folder => (
                            <div key={folder.folder_id} className={`folder ${openFolders[folder.folder_id] ? 'open' : ''}`}
                                onMouseEnter={() => handleMouseEnter(folder.folder_id)}
                                onMouseLeave={handleMouseLeave}>
                                <button className={`folder-title ${openFolders[folder.folder_id] ? 'active' : ''}`} onClick={() => toggleFolder(folder.folder_id)}>
                                    <span className="folder-text">
                                        {truncate(folder.folder_name, 23)}
                                    </span>
                                    <div>
                                        {!folder.is_default &&
                                            <button class='icon-button me-2 ' onClick={(event) => {
                                                event.stopPropagation();
                                                showModal("EDIT-PROMPT-FOLDER", { name: folder.folder_name }, { onEditPromptFolder: (newname) => handleEditPromptFolder(newname, folder.folder_id), onDeletePromptFolder: () => handleDeletePromptFolder(folder.folder_id) })
                                            }
                                            }>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF981A" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                </svg>
                                            </button>}
                                        ▶
                                    </div>

                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center  align-items-center  mt-2 mb-2 ">
                        <div className="dark-color  me-2">{selectedText === "" ? t("Apply to entire document") : t("Apply to selected text")}</div>
                        <span class="theme-color"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                                <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                            </svg>

                            {selectedText !== "" ?
                                Math.ceil(selectedText.length / 10000)
                                : Math.ceil(fileHtmlContent.length / 10000)}
                        </span>
                    </div>
                    <div class="ai-chat-msg-box ">
                        <MaxHeightInput
                            externalTextareaRef={promptTextRef}
                            onClick={onPromptBoxClick}
                            handleMouseDown={onPromptMouseDown}
                            placeholder={t("Tell me what to do")}
                            value={question}
                            onChange={onPromptChange}
                        />
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        {question !== '' &&
                            <span className="mt-2 me-2 pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title={t("Save Prompt")}
                                data-bs-original-title="Save Prompt" onClick={() => onSavePrompt(question)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF981A" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                            </span>}


                        <button className="btn theme-btn  rounded-btn    mt-2" style={{ padding: '5px 10px' }} onClick={() => generate_answer()} disabled={question === '' || streamInProgress.current || waitingStreamResponse}>
                            <span className="me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.873" height="18.47" viewBox="0 0 22.873 24.47">
                                    <g id="noun-chatbot-4453379" transform="translate(-1.384 -2.5)">
                                        <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.674" cy="1.674" r="1.674" transform="translate(8.107 12.52)" fill="#f9fcff" />
                                        <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.674" cy="1.674" r="1.674" transform="translate(14.469 12.52)" fill="#f9fcff" />
                                        <path id="Path_170" data-name="Path 170" d="M21.906,6.158V2.5H18.558V5.926H13.87V2.5H10.521V6.132A7.515,7.515,0,0,0,4.7,13.447V15.43a7.528,7.528,0,0,0,1.958,5.049A7.412,7.412,0,0,0,12.2,22.926H14l.026,4.044,5.847-4.044h.206a7.519,7.519,0,0,0,7.5-7.5V13.447A7.521,7.521,0,0,0,21.906,6.158Zm2.318,9.273a4.144,4.144,0,0,1-4.147,4.147H18.841l-4.765,3.3v-3.3H12.2a4.6,4.6,0,0,1-.592-.052,4.138,4.138,0,0,1-3.555-4.1V13.447A4.144,4.144,0,0,1,12.2,9.3h7.882a4.144,4.144,0,0,1,4.147,4.147Z" transform="translate(-3.316)" fill="#f9fcff" />
                                    </g>
                                </svg>
                            </span>
                            {t("Write")}
                        </button>


                    </div>

                    <div class="theme-color pointer mt-1 d-flex justify-content-center align-items-center"
                        onClick={() => setShowSettingsModal(true)}
                    ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill me-2" viewBox="0 0 16 16">
                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                        </svg>
                        <div class="mt-1">
                            {t("Settings")}</div>
                    </div>
                </div>
                <div className="hover-info"
                    style={{ display: promptDivHovered || hoveredFolder ? 'block' : 'none' }}
                    onMouseEnter={() => setPromptDivHovered(true)}
                    onMouseLeave={() => setPromptDivHovered(false)}
                >
                    {
                        hoveredPrompts.length > 0 ?
                            hoveredPrompts?.map((prompt, index) => (
                                <button onClick={() => handlePromptClick(prompt)} key={prompt.prompt_index} className="side-prompt-item">
                                    {truncate(prompt.title, 28)}
                                    {isFolderEditable(prompt.folder_id) &&
                                        <div class="dropdown " >
                                            <button class='icon-button' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" onClick={
                                                (event) => {
                                                    event.stopPropagation();
                                                }
                                            }>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                </svg>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ listStyleType: 'none' }}>
                                                <li><a class="dropdown-item" href="#" onClick={(e) => {
                                                    e.stopPropagation();
                                                    showModal("EDIT-PROMPT", { name: prompt.title, command: prompt.command }, { onEditPrompt: (newname, newcommand) => handleEditPrompt(newname, newcommand, prompt) })
                                                }

                                                }  >{t("Edit")}</a></li>
                                                <li><a class="dropdown-item" href="#" onClick={(e) => {
                                                    e.stopPropagation();
                                                    showModal('DELETE-PROMPT', { onDeletePrompt: () => handleDeletePrompt(prompt) })
                                                }
                                                } >{t("Delete")}</a></li>
                                            </ul>
                                        </div>}
                                </button>
                            ))

                            :
                            <div class="d-flex text-center justify-content-center">
                                <btn class=" theme-btn pointer" onClick={() => onSavePrompt("")}>{t("Create Prompt")}</btn>
                            </div>
                    }
                </div> </>}
            {(showCompareModal) &&
                <div className="comparison-body w-100 " >

                    <div className='d-flex justify-content-space-between '>

                        <div class="w-100  ms-4">
                            <h2 class="mini-title text-center mb-2">{t("Selected Text")}</h2>

                            {generatedTextToBeImproved && generatedTextToBeImproved !== '' ?
                                <div className='compare-editor'>
                                    <ReactQuill
                                        modules={
                                            {
                                                toolbar: {
                                                    container: [
                                                        [{ 'header': [] }],

                                                        ['bold', 'italic', 'underline', 'strike'],
                                                    ],
                                                }
                                            }
                                        }
                                        theme="snow"
                                        value={generatedTextToBeImproved}
                                        onChange={setGeneratedTextToBeImproved}


                                    />

                                </div>
                                :
                                <div className="ai-chat-msg-box mb-3  mt-2 d-flex align-items-center">
                                    <div className="form-control round-input border-input chatBox line"
                                        style={{
                                            overflow: 'auto',
                                            resize: 'none',
                                            height: `500px`,
                                            backgroundColor: 'white',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: selectedText }}
                                    ></div>
                                </div>}
                            <div class="text-center">
                                <button class="btn theme-btn red-bg mt-3 me-3 b-modal "
                                    onClick={onFinishClicked}
                                >{t("Finish")}</button>
                            </div>
                        </div>

                        <div style={{
                            position: 'relative',
                        }}>
                            <div className="side-div"
                                style={
                                    {
                                        height: '500px',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        padding: '10px 10px 14px 10px',
                                        margin: '44px 20px 28px 20px',
                                        overflow: 'auto',
                                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
                                    }
                                }
                            >
                                <div className="side-div-header">
                                    <h2 className="sidebar-title">{t("Edit with AI")}</h2>
                                    <button className="" onClick={onCreatePromptFolder} style={{ background: 'none', border: 'none' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF981A" class="bi bi-folder-plus" viewBox="0 0 16 16">
                                            <path d="m.5 3 .04.87a2 2 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2m5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 0 1 1-.98z" />
                                            <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="folders-container "
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {editPromptFolders.map(folder => (
                                        <div key={folder.folder_id} className={`folder ${openFolders[folder.folder_id] ? 'open' : ''}`}
                                            onMouseEnter={() => handleMouseEnter(folder.folder_id)}
                                            onMouseLeave={handleMouseLeave}>
                                            <button className={`folder-title ${openFolders[folder.folder_id] ? 'active' : ''}`} onClick={() => toggleFolder(folder.folder_id)}>
                                                <span className="folder-text">
                                                    {truncate(folder.folder_name, 23)}
                                                </span>
                                                <div>
                                                    {!folder.is_default &&
                                                        <button class='icon-button me-2 ' onClick={(event) => {
                                                            event.stopPropagation();
                                                            showModal("EDIT-PROMPT-FOLDER", { name: folder.folder_name }, { onEditPromptFolder: (newname) => handleEditPromptFolder(newname, folder.folder_id), onDeletePromptFolder: () => handleDeletePromptFolder(folder.folder_id) })
                                                        }
                                                        }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF981A" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                            </svg>
                                                        </button>}
                                                    ▶
                                                </div>

                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div class="ai-chat-msg-box "
                                >
                                    <MaxHeightInput
                                        externalTextareaRef={promptTextRef}
                                        autoFocus={true}
                                        onClick={onPromptBoxClick}
                                        handleMouseDown={onPromptMouseDown}
                                        placeholder={t("Tell me what to do")}
                                        value={question}
                                        onChange={onPromptChange}
                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {question !== '' &&
                                        <span className="mt-2 me-2 pointer"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title={t("Save Prompt")}
                                            data-bs-original-title="Save Prompt" onClick={() => onSavePrompt(question)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF981A" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                            </svg>
                                        </span>}
                                </div>
                            </div>
                            <div className="hover-info"
                                style={{ display: promptDivHovered || hoveredFolder ? 'block' : 'none' }}
                                onMouseEnter={() => setPromptDivHovered(true)}
                                onMouseLeave={() => setPromptDivHovered(false)}
                            >
                                {hoveredPrompts?.map((prompt, index) => (
                                    <button onClick={() => handlePromptClick(prompt)} key={prompt.prompt_index} className="side-prompt-item">
                                        {truncate(prompt.title, 28)}
                                        {isFolderEditable(prompt.folder_id) &&
                                            <div class="dropdown " >
                                                <button class='icon-button' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" onClick={
                                                    (event) => {
                                                        event.stopPropagation();
                                                    }
                                                }>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                    </svg>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ listStyleType: 'none' }}>
                                                    <li><a class="dropdown-item" href="#" onClick={(e) => {
                                                        e.stopPropagation();
                                                        showModal("EDIT-PROMPT", { name: prompt.title, command: prompt.command }, { onEditPrompt: (newname, newcommand) => handleEditPrompt(newname, newcommand, prompt) })
                                                    }

                                                    }  >{t("Edit")}</a></li>
                                                    <li><a class="dropdown-item" href="#" onClick={(e) => {
                                                        e.stopPropagation();
                                                        showModal('DELETE-PROMPT', { onDeletePrompt: () => handleDeletePrompt(prompt) })
                                                    }
                                                    } >{t("Delete")}</a></li>
                                                </ul>
                                            </div>}
                                    </button>
                                ))}
                            </div>
                            <div class="text-center mb-1">
                                <span class="theme-color "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                                    </svg>

                                    {generatedTextToBeImproved !== "" ?
                                        Math.ceil(generatedTextToBeImproved.length / 10000)
                                        : Math.ceil(fileHtmlContent.length / 10000)}
                                </span>
                            </div>
                            <div className="text-center">

                                {generatedTexts.length > 0 ?
                                    <button class="btn theme-btn white-btn-bg b-modal"
                                        disabled={streamInProgress.current || waitingStreamResponse}
                                        onClick={() => generate_answer()}
                                    >{t("Regenerate")}
                                        <Icon class='ms-2' data-bs-toggle="tooltip" data-bs-placement="bottom" path={mdiAutoMode} size={1.2} />
                                    </button>
                                    :
                                    <button className="btn theme-btn " onClick={() => generate_answer()} disabled={question === '' || streamInProgress.current || waitingStreamResponse}>
                                        <span className="me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.873" height="18.47" viewBox="0 0 22.873 24.47">
                                                <g id="noun-chatbot-4453379" transform="translate(-1.384 -2.5)">
                                                    <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.674" cy="1.674" r="1.674" transform="translate(8.107 12.52)" fill="#f9fcff" />
                                                    <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.674" cy="1.674" r="1.674" transform="translate(14.469 12.52)" fill="#f9fcff" />
                                                    <path id="Path_170" data-name="Path 170" d="M21.906,6.158V2.5H18.558V5.926H13.87V2.5H10.521V6.132A7.515,7.515,0,0,0,4.7,13.447V15.43a7.528,7.528,0,0,0,1.958,5.049A7.412,7.412,0,0,0,12.2,22.926H14l.026,4.044,5.847-4.044h.206a7.519,7.519,0,0,0,7.5-7.5V13.447A7.521,7.521,0,0,0,21.906,6.158Zm2.318,9.273a4.144,4.144,0,0,1-4.147,4.147H18.841l-4.765,3.3v-3.3H12.2a4.6,4.6,0,0,1-.592-.052,4.138,4.138,0,0,1-3.555-4.1V13.447A4.144,4.144,0,0,1,12.2,9.3h7.882a4.144,4.144,0,0,1,4.147,4.147Z" transform="translate(-3.316)" fill="#f9fcff" />
                                                </g>
                                            </svg>
                                        </span>
                                        {t("Write")}
                                    </button>}
                            </div>

                        </div>
                        <div class="w-100">
                            <h2 class="mini-title text-center mb-2">{t("Generated Text")}</h2>

                            <div className="ai-chat-msg-box mb-3  mt-2 d-flex align-items-center">

                                {waitingStreamResponse ?
                                    <div className="form-control round-input border-input chatBox "
                                        style={{
                                            overflow: 'auto',
                                            resize: 'none',
                                            height: '500px',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <div class='text-center mt-5'>
                                            <div className="spinner-border text-primary" role="status">

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div

                                        className="form-control round-input border-input chatBox line"
                                        ref={generatedTextRef}
                                        onScroll={(e) => {
                                            const isAtBottom = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <= 1;

                                            autoScroll.current = isAtBottom;

                                        }}
                                        style={{
                                            overflow: 'auto',
                                            resize: 'none',
                                            height: '500px',
                                            backgroundColor: 'white',

                                        }}
                                        dangerouslySetInnerHTML={{ __html: generatedText }}
                                    >
                                    </div>
                                }
                            </div>
                            <nav aria-label="..." className={`${(generatedTexts.length < 2) ? 'd-none' : ''}`}>
                                <ul className='pagination pagination-sm '>
                                    {Array.from({ length: Math.ceil(generatedTexts.length) }, (_, i) => i + 1).map(page => (
                                        <li
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title={generatedTexts[page - 1].prompt}
                                            key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                            <Link className="page-link" to="#" onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentPage(page);
                                            }}>
                                                {page}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            {((generatedText && generatedText != "")) &&
                                <div class="text-center">

                                    <button class="btn theme-btn mt-3 me-3 b-modal"
                                        disabled={streamInProgress.current || waitingStreamResponse}
                                        onClick={
                                            () => {
                                                setQuestion("")
                                                onAcceptChanges()
                                                setShowCompareModal(false)
                                                setGeneratedTexts([])
                                            }}
                                    >{t("Accept")}</button>
                                    <button class="btn theme-btn theme-border-btn mt-3 b-modal"
                                        disabled={streamInProgress.current || waitingStreamResponse}
                                        onClick={
                                            () => {
                                                improveGeneratedText()
                                            }}
                                    >{t("Improve this")}</button>
                                </div>}
                        </div>
                    </div>
                </div>
            }
        </>

    );
}

export default SideDiv;
