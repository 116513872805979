import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';

import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import { useModal } from '../components/common/Modal/ModalContext';
import { fetchData } from '../hooks/apiUtils';

import upgrade from '../assets/img/icon/upgrade.svg';
import folders from '../assets/img/icon/folders.svg';
import ImportFile from './Dashboard/ImportFile';
import PromptGenerator from './Dashboard/PromptGenerator';
import { useTranslation } from '../components/common/LanguageProvider';
import logoMeetingtor from '../assets/img/logo_meetingtor.png';
import logoRecorditor from '../assets/img/logo_recorditor.png';
import logoTranskriptor from '../assets/img/logo-transkriptor-nobuffer.png';
import ArticleWriter from './Dashboard/ArticleWriter';
function Dashboard({ isBlocked, setIsBlocked }) {
  const { hashedId, userData } = useAuth();
  const [minutes, setMinutes] = useState(30);
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});
  const location = useLocation();

  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    if (userData) {
      setMinutes(userData.Minutes);
    }
  }, [userData]);
  useEffect(() => {
    if (!localStorage.getItem("transcript-language"))
      findLanguage();
    if (!localStorage.getItem("isLanguageSet")) {
      localStorage.setItem("isLanguageSet", true);
      showModal("LANGUAGE-MODAL");

    }
  }, []);
  const isChrome = window.navigator.userAgent.includes("Chrome")

  const handleNavigate = (e, brand) => {
    e.preventDefault();
    // Get the current path
  
    // Construct new URL
    const newURL = `https://app.${brand}.com/signIn?uid=${hashedId}`;

    // Redirect to the new URL
    if (isChrome) {
      window.open(newURL, "_blank");
    } else {
      window.location.href = newURL;
    }
  };

  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", browserLanguage);
      window.location.reload();

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };


  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };


  const handleCheckboxChange = (fileId, isChecked) => {
    setCheckedItems(prevState => {
      // Step 1: Create a temporary copy of the checkedItems state
      const tempArray = { ...prevState };

      // Step 2: Modify the temporary array
      tempArray[fileId] = isChecked;

      // Get checked items from the updated temporary array
      const checkedIds = Object.keys(tempArray).filter(id => tempArray[id]);

      // Do something if the length of checked items is more than 0
      if (checkedIds.length > 0) {
        // Your logic here
        console.log('One or more items are checked!');
      }

      // Step 3: Return the modified temporary array to update the state
      return tempArray;
    });
  };

  useEffect(() => {
    console.log(checkedItems, "checkedItems")
  }, [checkedItems]);



  return (
    <>

      <div className='row justify-content-center'>
        <div className='col-lg-4 card-margin'>
          <div className='card card-padding card-100 '>
            <PromptGenerator minutes={minutes} />
          </div>
        </div>
        <div class="col-lg-4 card-margin" >
        <div className='card card-padding card-100 '>
          <ArticleWriter minutes={minutes} />
          </div>
        </div>
        <div className='col-lg-4 '>


          <ImportFile minutes={minutes} />
          <div className='card-margin'>

            <div className='card card-padding  '>
              <Files isFile={false} hideOptions={true} />
            </div>
          </div>

        </div>
     
 
        <div class="col-lg-4 card-margin" >
        <a
        href= {`https://app.meetingtor.com/signIn?uid=${hashedId}`}
        >
          <div class="card card-padding card-100  text-center card-hover" onClick={(e) => handleNavigate(e, "meetingtor")}>
            <div className='img-icon-list'>
              <img src={logoMeetingtor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
            </div>
            <p class="ft-18-title">{t("Smart Meeting Recorder")}</p>

            <p>{t("Record all meetings with just a click. Share, transcribe, and document your meeting recordings in one platform.")}</p>
          </div>
          </a>
        </div>

        <div class="col-lg-4 card-margin" >
        <a
        href= {`https://app.recorditor.com/signIn?uid=${hashedId}`}
        >
          <div class="card card-padding card-100  text-center card-hover" onClick={(e) => handleNavigate(e, "recorditor")}>
            <div className='img-icon-list'>
              <img src={logoRecorditor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
            </div>
            <p class="ft-18-title">{t("Online Screen Recording")}</p>

            <p>{t("Record your screen and camera with a single click. Create and share video messages with an online screen recorder.")}</p>
          </div>
          </a>
        </div>
        <div class="col-lg-4 card-margin" >
        <a
        href= {`https://app.transkriptor.com/signIn?uid=${hashedId}`}
        >
          <div class="card card-padding card-100  text-center card-hover" onClick={(e) => handleNavigate(e, "transkriptor")}>
            <div className='img-icon-list'>
              <img src={logoTranskriptor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
            </div>
            <p class="ft-18-title">{t("Convert Audio To Text")}</p>

            <p>{t("Automatically transcribe your meetings, interviews, lectures, and other conversations.")}</p>
          </div>
          </a>
        </div>
      </div>
    </>
  );
}

export default Dashboard;



