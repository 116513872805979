export const fetchData = async (url) => {
    const response = await fetch(url);
    return await response.json();
};


export const fetchDataPost = async (url, data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };
    const response = await fetch(url, requestOptions)
    return response;
}