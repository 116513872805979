// FileItem.js
import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useModal } from '../../components/common/Modal/ModalContext'
import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../components/common/LanguageProvider';
function FoldersItem({ folder, onDelete, onRename }) {

    const uid = folder?.PK?.S.replace('User#', "");
    const fid = folder?.SK?.S.replace('User#', '');
    const name = folder?.Fname?.S;
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { showModal } = useModal();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        function handleDocumentClick(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);
    return (
        <tr>
            <td>
                <span class="theme-color">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-folder" viewBox="0 0 16 16">
                        <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
                    </svg>
                </span>
            </td>
            <td class="pointer">
                <Link to={`/folder/${fid}/${uid}`}>
                    <span class="dark-color regular filename" >{name}</span>
                </Link>
            </td>
            <td className='text-end'>
                <div className='action-icon-list res-action-list'>
                    <Link onClick={() => showModal('RENAME', { tid: fid, uid: uid, name: name }, { onRename })}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                            <img src={renameSvg} className="img-fluid" alt="img" />
                        </span>
                    </Link>
                    <Link onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: uid }, { onDeleteFolder: onDelete })}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Delete")}>
                            <img src={deleteSvg} className="img-fluid" alt="img" />
                        </span>
                    </Link>
                </div>
                <div class="dropdown dropstart res-action-dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false" data-bs-toggle="dropdown">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                        <li><Link className='dropdown-item' onClick={() => showModal('RENAME', { tid: fid, uid: uid, name: name }, { onRename })}>
                            <span class="action-drop-icon">
                                <img src={renameSvg} class="img-fluid" alt="img" />
                            </span>{t("Rename")}
                        </Link></li>
                        <li><Link className='dropdown-item' onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: uid }, { onDeleteFolder: onDelete })}>
                            <span class="action-drop-icon">
                                <img src={deleteSvg} class="img-fluid" alt="img" />
                            </span>{t("Delete")}
                        </Link></li>
                    </ul>
                </div>
            </td>

        </tr>
    );
}

export default FoldersItem;